import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Emitter from '../utils/event-emitter';
import * as localStorage from '../utils/local-storage-helper';
import AppHeader from './AppHeader';
import Breadcrumb from './Breadcrumb';
import './dashboard/style.css';
import SideBar from './sidebar-styled/Sidebar.js';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { saveBreadCrumbData } from '../actions/breadCrumbActions';
import { saveUserUsageData } from '../actions/userActions';
import { saveDataFactorySelectedTab, saveFromMyFav } from '../actions/dataFactoryActions';
import { saveUserUsageRecord, exportPowerbiTest } from '../utils/api-helper';
import { urlSlug } from '../utils/common-helper';
import { useSidebarData } from './sidebar-styled/useSidebarData';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CollapseNav, ExpandVav } from '../assets/icons';
import AppFooter from './footer/footer.js';
import { saveSidebarToggle } from '../actions/sideNavigationBarActions';
let prevkey = '';
const currentEnvironment = window.currentEnvironment;

function AppPortal(props) {
    //----------------
    // Required for footer functionality on home page
    //----------------
    const domMainAreaElementRef = useRef(null);

    const [scrollBarIsAtTheEnd, setScrollBarIsAtTheEnd] = useState(null);
    const callbackDynamicFooter = (event) => {
        const weAreAtTheBottomOfScrollPosition = () => {
            const clientHeight = event.target.clientHeight;
            const scrollHeight = event.target.scrollHeight;
            const scrollTop = event.target.scrollTop;

            if (scrollTop === 0) return null;

            const calc = scrollHeight - clientHeight - scrollTop;
            const calc2 = calc >= -2 && calc <= 100;
            // For debugging:
            // console.log('calc1 -->', calc);
            // console.log('calc2 -->', calc2);
            // console.log('-----------------');

            const valToReturn = calc2;
            return valToReturn;
        };

        const atBottom = weAreAtTheBottomOfScrollPosition();

        if (atBottom) {
            setScrollBarIsAtTheEnd(true);
        } else {
            setScrollBarIsAtTheEnd(false);
        }
    };

    useEffect(() => {
        if (!domMainAreaElementRef.current) return;

        if (['/home', '/home/'].includes(window.location.pathname)) {
            domMainAreaElementRef.current.addEventListener('scroll', callbackDynamicFooter);
        } else {
            domMainAreaElementRef.current.removeEventListener('scroll', callbackDynamicFooter);
        }
    }, [domMainAreaElementRef.current, window.location.pathname]);
    //----------------

    // let SBList = SBarList();
    const [SBList, isLoading] = useSidebarData(props.sideBarRefresh);
    const [headerTitle, setHeaderTitle] = useState('Home');
    const [locationKeys, setLocationKeys] = useState([]);
    const [lodsPages, setLodsPages] = useState('Overview');
    const [openModal, setOpenModal] = useState(false);
    const [field, setField] = useState(null);
    const [crumbs, setCrumbs] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const sidebarDOMRef = useRef();
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => {
        props.saveSidebarToggle(!sidebar);
        setSidebar(!sidebar);
    };
    const [sidebarPosition, setSidebarPosition] = useState({
        sidebarHeightFromTop: 0,
        sidebarWidth: 0
    });
    //confirm
    const [visible, setVisible] = useState(false);

    //----------------
    // Handling the positioning of the sidebar expansion toggle button
    //----------------
    useEffect(() => {
        const sidebarDOMElement = sidebarDOMRef.current;
        const myObserver = new ResizeObserver(() => {
            // this will get called whenever div dimension changes
            const objToSet = {
                sidebarHeightFromTop: sidebarDOMElement.getBoundingClientRect().top,
                sidebarWidth: sidebarDOMElement.offsetWidth
            };
            setSidebarPosition(objToSet);
        });

        // start listening to dimension changes
        myObserver.observe(sidebarDOMElement);
    }, []);
    //----------------

    //----------------
    // PowerBi Token Refresh
    //----------------
    useEffect(() => {
        // Utility function to fetch and store token particulars
        const fetchAndStoreTokenParticularsInSession = async function () {
            try {
                const response = await exportPowerbiTest();
                if (response?.data) {
                    let res = JSON.parse(response?.data);
                    localStorage.set('PBI_ACCESS_TOKEN', res?.accessToken);
                    if (res?.reportConfig?.length > 0) {
                        localStorage.set('PBI_REPORTS', JSON.stringify(res?.reportConfig));
                    }
                }
            } catch (err) {
                console.log(err);
            }
        };

        // Replace the existing interval reference and interval if running
        const tokenTimerExists = JSON.parse(sessionStorage.getItem('tokenTimer', null));
        if (tokenTimerExists) {
            clearInterval(tokenTimerExists);
        }

        // Fetch the token immediately
        fetchAndStoreTokenParticularsInSession();

        // Keep refreshing the token every xMiliSeconds
        const xMiliSeconds = 2_700_000; //45 minutes <-- https://www.calculateme.com/time/minutes/to-milliseconds/45
        const timerReference = setInterval(() => {
            console.log('TIMER IS CALLED');
            fetchAndStoreTokenParticularsInSession();
        }, xMiliSeconds);

        sessionStorage.setItem('tokenTimer', timerReference);
    }, []);
    //----------------

    //----------------
    // State to instantiate CSS to disable loader in case of API 500 range error
    //----------------
    const [disableLoader, setDisableLoader] = useState(false);

    useEffect(() => {
        setDisableLoader(false);
    }, [history.location.pathname]);
    //----------------

    //trigger when getting error from api call
    useEffect(() => {
        //initial emit event at once
        Emitter.on('ERROR_DATA_NOT_AVAILABLE', (error) => {
            let dontDisplayPopUp = false;
            const currentWindowURL = window.location.href;
            const urlCases = ['insights-studio'];
            urlCases.forEach((urlCase) => {
                if (currentWindowURL && currentWindowURL.includes(urlCase)) {
                    dontDisplayPopUp = true;
                }
            });
            if (dontDisplayPopUp) setVisible(false);

            if (error && !visible && !dontDisplayPopUp) setVisible(true);
        });

        Emitter.on('LOGOUT_EMITTER', (data) => {
            if (data) accept();
        });

        //call when exit from page
        return () => Emitter.off('ERROR_DATA_NOT_AVAILABLE');
    }, []);

    const accept = () => {
        Emitter.off('ERROR_DATA_NOT_AVAILABLE');

        //clears all route history
        localStorage.clearAllLocalStorage();
        window.location.reload();
        props?.history?.push('/');
        //below will reset all the reducers to their default states
        dispatch({ type: 'USER_LOGOUT' });
    };
    //Logout

    const rejectLogout = () => { };

    const LogoutFunction = () => {
        confirmDialog({
            message: 'Are you sure you want to Logout?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept,
            rejectLogout
        });
    };

    useEffect(() => {
        //logic to handle browser's back button click event

        return history.listen((location) => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key]);
            }
            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    //Handle forward event
                    setLocationKeys(([_, ...keys]) => keys);
                } else {
                    //Handle back event
                    // setLocationKeys((keys) => [location.key, ...keys]);
                    let currentKey = locationKeys?.length ? locationKeys[0] : '';

                    if (history?.location?.pathname != '/home' && prevkey != currentKey) history.goBack();
                    setTimeout(() => {
                        prevkey = currentKey;
                    }, 500);
                }
            }
        });
    }, [locationKeys]);
    //Logout callback

    useEffect(() => {
        let loginT = new Date();
        return () => {
            if (props.userData.isAuthenticated) {
                // if (((props.userUsageData && props.userUsageData.page_name !== props.breadcrumb[props.breadcrumb.length - 1]) || !props.userUsageData) && props.breadcrumb[props.breadcrumb.length - 1]) {
                if (currentEnvironment == 'PROD' || currentEnvironment == 'PRODNEW' || currentEnvironment == 'DEMO' || currentEnvironment == 'UAT') {
                    // currentEnvironment !== 'PROD  originally
                    //|| currentEnvironment == 'TEST'
                    saveCurrentUsage(loginT);
                } else {
                    if (props.userData.user.userDetails.userName.indexOf('@cognitioanalytics.com') === -1) {
                        saveCurrentUsage(loginT);
                    }
                }
            }
        };
    }, [location?.pathname]);

    const saveCurrentUsage = (loginT) => {
        let usageObj = {
            user_id: '',
            page_name: '',
            login_time: null,
            uniqueSessionId: null,
            logout_time: null,
            user_name: '',
            module: '',
            role: ''
        };
        usageObj.user_id = props?.userData?.user?.userDetails?.userName;
        usageObj.page_name = props.breadcrumb[props.breadcrumb.length - 1]?.includes("'")
            ? props.breadcrumb[props.breadcrumb.length - 1].replace(/'/g, '')
            : props?.breadcrumb[props.breadcrumb.length - 1]
                ? props.breadcrumb[props.breadcrumb.length - 1]
                : 'Home';
        usageObj.login_time = loginT;
        usageObj.session_id = props?.userData?.uniqueSessionId;
        usageObj.logout_time = new Date();
        usageObj.user_name = props?.userData?.user?.userDetails?.name;
        usageObj.module = props?.breadcrumb?.length > 1 ? props.breadcrumb[1] : 'Home';
        usageObj.role = sessionStorage.getItem('USER_ROLE', null) ? sessionStorage.getItem('USER_ROLE', null) : '';
        props.saveUserUsageData(usageObj);
        //if(currentEnvironment != "PROD"){
        saveUsageAPICall(usageObj, '');
        //}
        //TO_DO
        // API call saveUsageAPICall(usageObj, from);
        // for logout time (need handling from backend);
        // from === "logout" ? props.saveUserUsageData(null);
    };

    const saveUsageAPICall = (payload, from) => {
        saveUserUsageRecord(payload)
            .then((response) => {
                if (from === 'logout') {
                    window.location.reload();
                }
            })
            .catch((error) => {
                if (from === 'logout') {
                    window.location.reload();
                }
            });
    };

    const contentCallback = (item, level, isPlus) => {
        let dummyBreadcrumb = [];
        if (item?.title) {
            switch (level) {
                case 1:
                    if (item.title === 'Home') {
                        props.saveBreadCrumbData(['Home']);
                    } else if (item.title === 'My Favorites' && !isPlus) {
                        // props.saveBreadCrumbData(['Home']);
                    } else {
                        const moduleTitle = item.title === 'High Cost Claimants' ? 'High-Cost Claimants' : item.title;
                        if (isPlus) {
                            dummyBreadcrumb.push('Home', moduleTitle);
                            setCrumbs(dummyBreadcrumb);
                        } else {
                            props.saveBreadCrumbData(['Home', moduleTitle]);
                            setHeaderTitle([moduleTitle]);
                        }
                    }

                    break;
                case 2:
                    if (
                        item.title === 'Master Data' ||
                        item.title === 'Detailed Report' ||
                        item.title === 'Request Service'
                    ) {
                        props.saveBreadCrumbData(['Home', 'Data Factory', item.title]);
                    } else if (item.title === 'Data Sources') {
                        dummyBreadcrumb.push('Home', 'Data Factory', 'Data Sources');
                        setCrumbs(dummyBreadcrumb);
                    } else if (item.title == 'Query Builder' || item.title == 'query-builder') {
                        props.saveBreadCrumbData(['Home', 'Insights Studio', 'Query Builder']);
                    } else if (item.title == 'View Report' || item.title == 'view-report') {
                        props.saveBreadCrumbData(['Home', 'Insights Studio', 'Query Builder', 'View Report']);
                    } else if (
                        item.title.toLowerCase() == 'healthcare fraud matter 1' ||
                        item.title.toLowerCase() == 'healthcare fraud matter 2' ||
                        item.title.toLowerCase() == 'healthcare fraud matter 3' ||
                        item.title.toLowerCase() == 'hcfm additional details'
                    ) {
                        props.saveBreadCrumbData(['Home', 'Healthcare Fraud Matter', 'Healthcare', item.title]);
                    } else if (
                        item?.parent?.toLowerCase() == 'high-cost-claimants' &&
                        (item.title.toLowerCase() == 'overview' ||
                            item.title.toLowerCase() == 'medical drilldown' ||
                            item.title.toLowerCase() == 'pharmacy drilldown')
                    ) {
                        props.saveBreadCrumbData(['Home', 'High-Cost Claimants', item.title]);
                    } else if (crumbs.length > 0 && props.breadcrumb[1] !== crumbs[1]) {
                        setCrumbs([props.breadcrumb[0], props.breadcrumb[1], item.title]);
                    } else if (crumbs[1] === 'Work In Process' && item.title !== '') {
                        let arr = [];
                        if (isPlus) {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });

                            setCrumbs(arr);
                            //used for navigating when click on subnav
                            props.saveBreadCrumbData(['Home', crumbs[1]]);
                        } else {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });
                            setCrumbs(arr);
                            props.saveBreadCrumbData(arr);
                        }

                        setHeaderTitle([crumbs[1]]);
                    } else if (crumbs[1] === 'Physical Wellbeing' && item.title !== '') {
                        let arr = [];
                        if (isPlus) {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });

                            setCrumbs(arr);
                            //used for navigating when click on subnav
                            props.saveBreadCrumbData(['Home', crumbs[1]]);
                        } else {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });
                            setCrumbs(arr);
                            props.saveBreadCrumbData(arr);
                        }
                        setHeaderTitle([crumbs[1]]);
                    } else if (crumbs[1] === 'Risk Score' && item.title !== '') {
                        let arr = [];
                        if (isPlus) {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });

                            setCrumbs(arr);
                            //used for navigating when click on subnav
                            props.saveBreadCrumbData(['Home', crumbs[1]]);
                        } else {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });
                            setCrumbs(arr);
                            props.saveBreadCrumbData(arr);
                        }
                        setHeaderTitle([crumbs[1]]);
                    } else if (
                        (crumbs[1] === 'Mental Wellbeing' || crumbs[1] === 'Emotional Wellbeing') &&
                        item.title !== ''
                    ) {
                        let arr = [];
                        if (isPlus) {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });

                            setCrumbs(arr);
                            //used for navigating when click on subnav
                            props.saveBreadCrumbData(['Home', crumbs[1]]);
                        } else {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });
                            setCrumbs(arr);
                            props.saveBreadCrumbData(arr);
                        }
                        setHeaderTitle([crumbs[1]]);
                    } else if (crumbs[1] === 'Standard Reports' && item.title !== '') {
                        let arr = [];
                        if (isPlus) {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });

                            setCrumbs(arr);
                            //used for navigating to standard report when click on subnav
                            props.saveBreadCrumbData(['Home', crumbs[1]]);
                        } else {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });
                            setCrumbs(arr);
                            props.saveBreadCrumbData(arr);
                        }

                        setHeaderTitle([crumbs[1]]);
                    } else if (crumbs[1] === 'Product Evaluation' && item.title !== '') {
                        let arr = [];
                        if (isPlus) {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });

                            setCrumbs(arr);
                            //used for navigating when click on subnav
                            props.saveBreadCrumbData(['Home', crumbs[1]]);
                        } else {
                            SBList?.forEach((obj) => {
                                if (obj.title === crumbs[1]) {
                                    obj.subNav?.forEach((ele) => {
                                        if (ele.title === item.title) {
                                            if (ele?.subNav2?.length !== 0) {
                                                arr.push('Home', crumbs[1], item.title, ele?.subNav2[0].title);
                                            }
                                        }
                                    });
                                }
                            });
                            setCrumbs(arr);
                            props.saveBreadCrumbData(arr);
                        }
                        setHeaderTitle([crumbs[1]]);
                    } else {
                        if (item.title !== 'Data Sources') {
                            let arr = [];
                            arr.push('Home');
                            if (isPlus) {
                                if (item.hasOwnProperty('breadcrum')) {
                                    //moved to isPlus as flag always true
                                    SBList?.forEach((obj) => {
                                        if (obj.title.toLowerCase() === 'my favorites') {
                                            obj?.subNav?.forEach((ele) => {
                                                //first we check if pathCheck exists on item
                                                if (item.hasOwnProperty('pathCheck')) {
                                                    //when path check exists compare title & path w saved path
                                                    if (ele.title == item.title && ele.path[0] == item.pathCheck) {
                                                        props.saveBreadCrumbData(ele.breadcrum[0].split(','));
                                                        if (ele.tab) {
                                                            //this will open the correct bookmarked tab as
                                                            //it will save the tab in dataFSelectedTab reducer that opens the said tab
                                                            props.saveDataFactorySelectedTab(ele.tab);
                                                            props.saveFromMyFav(true);
                                                        }
                                                    }
                                                } else if (ele.title == item.title && ele.path == item.path) {
                                                    //check the path and title in case title but modules are different
                                                    //this block run when there is no pathCheck on item
                                                    //i.e. when my fav report are accessed directly from sidenav
                                                    props.saveBreadCrumbData(ele.breadcrum[0].split(','));
                                                    if (ele.tab) {
                                                        //this will open the correct bookmarked tab as
                                                        //it will save the tab in dataFSelectedTab reducer that opens the said tab
                                                        props.saveDataFactorySelectedTab(ele.tab);
                                                        props.saveFromMyFav(true);
                                                    }
                                                }
                                            });
                                        } else if (obj.title === 'High-Cost Claimants') {
                                            obj?.subNav?.forEach((ele) => {
                                                if (ele.title === item.title) {
                                                    arr.push(obj.title, ele.title);
                                                }
                                            });
                                            props.saveBreadCrumbData(arr);
                                        }
                                    });
                                } else {
                                    SBList.forEach((obj) => {
                                        obj?.subNav?.forEach((ele) => {
                                            if (ele.title === item.title) {
                                                arr.push(obj.title, ele.title);
                                            }
                                        });
                                    });
                                    setCrumbs(arr);
                                }
                            }
                        }
                    }
                    props.breadcrumb[1] && setHeaderTitle([props?.breadcrumb[1]]);
                    break;
                case 3:
                    if (props.breadcrumb[1] === 'Data Factory' && props.breadcrumb[2] === 'Curated') {
                        props.saveBreadCrumbData(['Home', 'Data Factory', 'Curated', item.title]);
                        setHeaderTitle([props?.breadcrumb[1]]);
                    } else if (item.title === 'view-report' || item.title === 'View Report') {
                        props.saveBreadCrumbData(['Home', 'Insights Studio', 'Query Builder', 'View Report']);
                    } else if (props.breadcrumb[2]?.toLowerCase() == 'healthcare') {
                        props.saveBreadCrumbData(['Home', props.breadcrumb[1], 'Healthcare', item.title]);
                        setHeaderTitle([props?.breadcrumb[1]]);
                    } else if (crumbs?.length === 3 && crumbs[1] === 'Data Factory' && crumbs[2] === 'Data Sources') {
                        SBList.forEach((item1) => {
                            if (item1.title === 'Data Factory') {
                                item1?.subNav.forEach((ele) => {
                                    if (ele.title === 'Data Sources') {
                                        ele.subNav2.forEach((ele1) => {
                                            if (ele1.title === item.title) {
                                                props.saveBreadCrumbData([
                                                    'Home',
                                                    'Data Factory',
                                                    'Data Sources',
                                                    item.title
                                                ]);
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    } else if (crumbs[1] === 'Product Evaluation') {
                        let arr = [];
                        SBList?.forEach((obj) => {
                            if (obj.title === crumbs[1]) {
                                obj.subNav?.forEach((ele) => {
                                    if (ele.title === crumbs[2]) {
                                        ele?.subNav2?.forEach((itm) => {
                                            if (itm.title === item.title) {
                                                arr.push('Home', obj.title, ele.title, item.title);
                                            }
                                        });
                                    }
                                });
                            }
                        });

                        // props.saveBreadCrumbData(arr);
                        if (arr?.length) {
                            props.saveBreadCrumbData(arr);
                        } else {
                            props.saveBreadCrumbData(props.breadcrumb);
                        }
                        setHeaderTitle([crumbs[1]]);
                    } else if (crumbs[1] === 'Standard Reports') {
                        let arr = [];
                        SBList?.forEach((obj) => {
                            if (obj.title === crumbs[1]) {
                                obj.subNav?.forEach((ele) => {
                                    if (ele.title === crumbs[2]) {
                                        ele?.subNav2?.forEach((itm) => {
                                            if (itm.title === item.title) {
                                                arr.push('Home', obj.title, ele.title, item.title);
                                            }
                                        });
                                    }
                                });
                            }
                        });

                        // props.saveBreadCrumbData(arr);
                        if (arr?.length) {
                            props.saveBreadCrumbData(arr);
                        } else {
                            props.saveBreadCrumbData(props.breadcrumb);
                        }
                        setHeaderTitle([crumbs[1]]);
                    } else if (crumbs[1] === 'Work In Process') {
                        let arr = [];
                        SBList?.forEach((obj) => {
                            if (obj.title === crumbs[1]) {
                                obj.subNav?.forEach((ele) => {
                                    if (ele.title === crumbs[2]) {
                                        ele?.subNav2?.forEach((itm) => {
                                            if (itm.title === item.title) {
                                                arr.push('Home', obj.title, ele.title, item.title);
                                            }
                                        });
                                    }
                                });
                            }
                        });

                        // props.saveBreadCrumbData(arr);
                        if (arr?.length) {
                            props.saveBreadCrumbData(arr);
                        } else {
                            props.saveBreadCrumbData(props.breadcrumb);
                        }
                        setHeaderTitle([crumbs[1]]);
                    } else if (crumbs[1] === 'Physical Wellbeing') {
                        let arr = [];
                        SBList?.forEach((obj) => {
                            if (obj.title === crumbs[1]) {
                                obj.subNav?.forEach((ele) => {
                                    if (ele.title === crumbs[2]) {
                                        ele?.subNav2?.forEach((itm) => {
                                            if (itm.title === item.title) {
                                                arr.push('Home', obj.title, ele.title, item.title);
                                            }
                                        });
                                    }
                                });
                            }
                        });

                        // props.saveBreadCrumbData(arr);
                        if (arr?.length) {
                            props.saveBreadCrumbData(arr);
                        } else {
                            props.saveBreadCrumbData(props.breadcrumb);
                        }
                        setHeaderTitle([crumbs[1]]);
                    } else if (crumbs[1] === 'Risk Score') {
                        let arr = [];
                        SBList?.forEach((obj) => {
                            if (obj.title === crumbs[1]) {
                                obj.subNav?.forEach((ele) => {
                                    if (ele.title === crumbs[2]) {
                                        ele?.subNav2?.forEach((itm) => {
                                            if (itm.title === item.title) {
                                                arr.push('Home', obj.title, ele.title, item.title);
                                            }
                                        });
                                    }
                                });
                            }
                        });

                        // props.saveBreadCrumbData(arr);
                        if (arr?.length) {
                            props.saveBreadCrumbData(arr);
                        } else {
                            props.saveBreadCrumbData(props.breadcrumb);
                        }
                        setHeaderTitle([crumbs[1]]);
                    } else if (crumbs[1] === 'Mental Wellbeing' || crumbs[1] === 'Emotional Wellbeing') {
                        let arr = [];
                        SBList?.forEach((obj) => {
                            if (obj.title === crumbs[1]) {
                                obj.subNav?.forEach((ele) => {
                                    if (ele.title === crumbs[2]) {
                                        ele?.subNav2?.forEach((itm) => {
                                            if (itm.title === item.title) {
                                                arr.push('Home', obj.title, ele.title, item.title);
                                            }
                                        });
                                    }
                                });
                            }
                        });

                        // props.saveBreadCrumbData(arr);
                        if (arr?.length) {
                            props.saveBreadCrumbData(arr);
                        } else {
                            props.saveBreadCrumbData(props.breadcrumb);
                        }
                        setHeaderTitle([crumbs[1]]);
                    } else if (props?.breadcrumb?.length > 3 && props.breadcrumb[1] === 'Product Evaluation') {
                        let arr = [];
                        SBList?.forEach((obj) => {
                            if (obj.title === props.breadcrumb[1]) {
                                obj.subNav?.forEach((ele) => {
                                    if (ele.title === props.breadcrumb[2]) {
                                        ele?.subNav2?.forEach((itm) => {
                                            if (itm.title === item.title) {
                                                arr.push('Home', obj.title, ele.title, item.title);
                                            }
                                        });
                                    }
                                });
                            }
                        });

                        props.saveBreadCrumbData(arr);
                        setHeaderTitle([crumbs[1]]);
                    } else if (props?.breadcrumb?.length > 3 && props.breadcrumb[1] === 'Standard Reports') {
                        let arr = [];
                        SBList?.forEach((obj) => {
                            if (obj.title === props.breadcrumb[1]) {
                                obj?.subNav?.forEach((ele) => {
                                    if (ele.title === props.breadcrumb[2]) {
                                        ele?.subNav2?.forEach((itm) => {
                                            if (itm.title === item.title) {
                                                arr.push('Home', obj.title, ele.title, item.title);
                                            }
                                        });
                                    }
                                });
                            }
                        });
                        props.saveBreadCrumbData(arr);
                        setHeaderTitle([crumbs[1]]);
                    } else if (props?.breadcrumb?.length > 3 && props.breadcrumb[1] === 'Work In Process') {
                        let arr = [];
                        SBList?.forEach((obj) => {
                            if (obj.title === props.breadcrumb[1]) {
                                obj?.subNav?.forEach((ele) => {
                                    if (ele.title === props.breadcrumb[2]) {
                                        ele?.subNav2?.forEach((itm) => {
                                            if (itm.title === item.title) {
                                                arr.push('Home', obj.title, ele.title, item.title);
                                            }
                                        });
                                    }
                                });
                            }
                        });
                        props.saveBreadCrumbData(arr);
                        setHeaderTitle([crumbs[1]]);
                    } else if (props?.breadcrumb?.length > 3 && props.breadcrumb[1] === 'Physical Wellbeing') {
                        let arr = [];
                        SBList?.forEach((obj) => {
                            if (obj.title === props.breadcrumb[1]) {
                                obj?.subNav?.forEach((ele) => {
                                    if (ele.title === props.breadcrumb[2]) {
                                        ele?.subNav2?.forEach((itm) => {
                                            if (itm.title === item.title) {
                                                arr.push('Home', obj.title, ele.title, item.title);
                                            }
                                        });
                                    }
                                });
                            }
                        });
                        props.saveBreadCrumbData(arr);
                        setHeaderTitle([crumbs[1]]);
                    } else if (
                        props?.breadcrumb?.length > 3 &&
                        (props.breadcrumb[1] === 'Mental Wellbeing' || props.breadcrumb[1] === 'Emotional Wellbeing')
                    ) {
                        let arr = [];
                        SBList?.forEach((obj) => {
                            if (obj.title === props.breadcrumb[1]) {
                                obj?.subNav?.forEach((ele) => {
                                    if (ele.title === props.breadcrumb[2]) {
                                        ele?.subNav2?.forEach((itm) => {
                                            if (itm.title === item.title) {
                                                arr.push('Home', obj.title, ele.title, item.title);
                                            }
                                        });
                                    }
                                });
                            }
                        });
                        props.saveBreadCrumbData(arr);
                        setHeaderTitle([crumbs[1]]);
                    } else {
                        if (props?.breadcrumb[1] === 'Data Factory' || crumbs[1] === 'Data Factory') {
                            if (isPlus) {
                                SBList.forEach((sub) => {
                                    if (sub.title == 'Data Factory') {
                                        sub?.subNav.forEach((ele) => {
                                            if (ele.title == 'Data Sources') {
                                                ele?.subNav2?.forEach((x) => {
                                                    if (item.title == x.title) {
                                                        dummyBreadcrumb.push(
                                                            'Home',
                                                            'Data Factory',
                                                            ele.title,
                                                            x.title
                                                        );
                                                        setCrumbs(dummyBreadcrumb);
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            } else {
                                SBList.forEach((sub) => {
                                    if (sub.title == 'Data Factory') {
                                        sub?.subNav.forEach((ele) => {
                                            if (ele.title == 'Data Sources') {
                                                ele?.subNav2?.forEach((x) => {
                                                    if (item.title == x.title) {
                                                        props.saveBreadCrumbData([
                                                            'Home',
                                                            'Data Factory',
                                                            ele.title,
                                                            x.title
                                                        ]);
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        }
                    }

                    props.breadcrumb[1] && setHeaderTitle([props?.breadcrumb[1]]);
                    break;
                case 4:
                    let arr;
                    if (item?.path) {
                        arr = item?.path?.split('/');
                    } else {
                        arr = props?.location?.pathname?.split('/');
                    }

                    props.saveBreadCrumbData(['Home', 'Data Factory', 'Data Sources', arr[3], arr[4]]);

                    setHeaderTitle(['Data Factory']);
                    break;
                default:
                    break;
            }
        }
    };

    const CheckAuth = () => {
        const userData = useSelector((state) => state.user.userData);
        if (userData && userData.isAuthenticated) {
            return true;
        }
        return false;
    };

    const titleCase = (sentence) => {
        sentence = sentence.toLowerCase().split(' ');
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0]?.toUpperCase() + sentence[i].slice(1);
        }

        return sentence.join(' ');
    };

    let redirectCheck;

    const getOption = () => {
        let newList = [...props.breadcrumb];

        let option = newList?.length ? newList.pop() : '';

        return option;
    };

    let option = getOption();

    const getPathArr = () => {
        let pathString = history?.location?.pathname;

        pathString = pathString?.startsWith('/') ? pathString.slice(1) : pathString;

        let pathArr = pathString?.length ? pathString.split('/') : '';
        pathArr = pathArr?.length
            ? pathArr.map((item, index) => {
                if (index == 0 || item == 'data-sources') {
                    return item.replaceAll('-', ' ');
                } else {
                    return item;
                }
            })
            : pathArr;
        pathArr = pathArr?.length
            ? // ? pathArr.map((item) => titleCase(item))
            pathArr.map((item, index) => {
                if (index == 0 || item == 'impact across demographics') {
                    return titleCase(item);
                } else {
                    return item;
                }
            })
            : pathArr;
        if (
            pathArr[0]?.toLocaleLowerCase() == 'data factory' &&
            pathArr[1]?.toLocaleLowerCase() == 'curated' &&
            pathArr?.length == 3
        ) {
            //this block added to save the correct breadcrumb for curated tables when coming from my fav section
            props.saveBreadCrumbData(['Home', titleCase(pathArr[0]), titleCase(pathArr[1]), titleCase(pathArr[2])]);
        }
        return pathArr;
    };

    const getNewTitle = () => {
        let pathArr = getPathArr();

        if (pathArr.length) {
            let title = pathArr[pathArr?.length - 1];

            if (title == 'detailed-report' || title == 'master-data' || title == 'request-service') {
                if (title.toLowerCase().includes('-')) {
                    title = title
                        ?.split('-')
                        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        ?.join(' ');
                }

                return title;
            } else {
                return pathArr[pathArr?.length - 1];
            }
        } else {
            return 'Home';
        }
    };

    useEffect(() => {
        let newTitle = getNewTitle();
        if (newTitle === 'Dashboard' || newTitle === 'Home') {
            setTimeout(() => {
                props.saveBreadCrumbData(['Home']);
                setHeaderTitle(['Home']);
            }, 500);
        } else {
            contentCallback(
                { title: newTitle == 'detailed-report' ? 'Detailed Report' : newTitle, pathCheck: location.pathname },
                getPathArr()?.length
            );
        }
    }, [history?.location?.pathname]);

    useEffect(() => {
        if (SBList) {
            if (option === 'Home') {
                history.push(`/home`);
            } else if (option == 'My Profile') {
                history.push(`/my-profile`);
            } else if (option == 'Access Management') {
                history.push(`/access-management`);
            } else if (option == 'Work In Process') {
                history.push(`/work-in-process`);
            } else if (SBList?.some((ob) => ob?.title?.toLowerCase() == option?.toLowerCase())) {
                if (option === 'Home') return;
                history.push(`/${urlSlug(option)}`);
            } else if (option === 'Query Builder' && props.location.state) {
                history.push({
                    pathname: `/insights-studio/query-builder`,
                    state: props.location.state
                });
            } else if (option === 'Query Builder') {
                history.push({
                    pathname: `/insights-studio/query-builder`
                });
            } else if (option == 'View Report' || option === 'view-report') {
                history.push(`/insights-studio/query-builder/view-report`);
            } //Do not uncomment the following 4 redirects as they cause infinite rerender
            else if (option == 'Master Data') {
                // history.push(`/data-factory/master-data`);
            } else if (option == 'Request Service') {
                // history.push(`/data-factory/request-service`);
            } else if (option == 'Detailed Report' || option == 'detailed-report') {
                // history.push(`/data-factory/detailed-report`);
            } else if (props.breadcrumb[1] == 'Data Factory' && props.breadcrumb[2] == 'Curated') {
                // history.push({
                //   pathname: `/${urlSlug(props?.breadcrumb[1])}/curated/${option}`,
                //   state: { vendor: null, fileCategory: option}
                // })
            } else if (props.breadcrumb[1] == 'Data Factory' && props.breadcrumb[2] == 'Master Data') {
                history.push(`/${urlSlug(props?.breadcrumb[1])}/master-data/${option}`);
            } else if (
                SBList?.some((ob) => ob?.subNav?.some((ob1) => ob1?.subNav2?.some((ob2) => ob2?.title == option))) &&
                props?.breadcrumb[1] == 'Data Factory'
            ) {
                history.push(`/${urlSlug(props?.breadcrumb[1])}/data-sources/${option}`);
            } else if (
                SBList?.some((ob) =>
                    ob?.subNav?.some((ob1) =>
                        ob1?.subNav2?.some((ob2) => ob2?.subNav3?.some((ob3) => ob3?.title == option))
                    )
                ) &&
                props.breadcrumb.length > 3 &&
                crumbs[1] == 'Data Factory'
            ) {
                let mainModuleI = SBList?.findIndex((ob) => {
                    return ob?.title == 'Data Factory';
                });

                if (mainModuleI != undefined && mainModuleI != -1) {
                    let subModuleI;
                    SBList[mainModuleI]?.subNav[0]?.subNav2?.forEach((item, index) => {
                        item.subNav3?.forEach((ele) => {
                            if (ele.title === option) {
                                subModuleI = index - 1;
                            }
                        });
                    });

                    if (subModuleI != undefined && subModuleI != -1) {
                        history.push(`/${urlSlug(props?.breadcrumb[1])}/data-sources/${props.breadcrumb[3]}/${option}`);
                    }
                }
            } else if (
                SBList?.some((ob) =>
                    ob?.subNav?.some((ob1) =>
                        ob1?.subNav2?.some((ob2) => ob2?.subNav3?.some((ob3) => ob3?.title == option))
                    )
                ) &&
                props?.breadcrumb[1] == 'Data Factory'
            ) {
                let mainModuleI = SBList?.findIndex((ob) => {
                    return ob?.title == 'Data Factory';
                });
                if (mainModuleI != undefined && mainModuleI != -1) {
                    let subModuleI = SBList[mainModuleI]?.subNav[0]?.subNav2?.findIndex((ob) => {
                        return ob?.subNav3?.includes((o) => o?.title == option);
                    });
                    if (subModuleI != undefined && subModuleI != -1) {
                        history.push(
                            `/${urlSlug(props?.breadcrumb[1])}/data-sources/${SBList[mainModuleI]?.subNav[0]?.subNav2[subModuleI]
                            }/${option}`
                        );
                    }
                }
            } else if (
                SBList?.some((ob1) => ob1?.title == props.breadcrumb[1]) &&
                props.breadcrumb[2].toLowerCase() == 'healthcare'
            ) {
                history.push(`/${urlSlug(props?.breadcrumb[1])}/healthcare/${option}`);
            } else if (
                SBList?.some((ob1) => ob1?.title == props.breadcrumb[1]) &&
                props.breadcrumb[1].toLowerCase() == 'high-cost claimants'
            ) {
                history.push(`/${urlSlug(props?.breadcrumb[1])}/${option}`);
            } else if (
                SBList?.some((ob1) => ob1?.title == props.breadcrumb[1]) &&
                props.breadcrumb[1].toLowerCase() == 'work in process'
            ) {
                let mainModuleI = SBList?.findIndex((ob) => {
                    return ob?.title == props?.breadcrumb[1];
                });
                if (mainModuleI != undefined && mainModuleI != -1) {
                    let subModuleI = SBList[mainModuleI]?.subNav?.findIndex((ob) => {
                        return ob?.title == props.breadcrumb[2];
                    });

                    if (subModuleI != undefined && subModuleI != -1) {
                        let subModuleI2 = SBList[mainModuleI]?.subNav[subModuleI].subNav2.findIndex((ob) => {
                            return ob?.title == option;
                        });

                        if (subModuleI2 != undefined && subModuleI2 != -1) {
                            history.push({
                                pathname: `/${urlSlug(props?.breadcrumb[1])}/${SBList[mainModuleI]?.subNav[subModuleI]?.title
                                    }/${option}`,
                                state: {
                                    reportName:
                                        SBList[mainModuleI]?.subNav[subModuleI]?.subNav2[subModuleI2]?.report_name
                                }
                            });
                        }
                    }
                }
            } else if (
                SBList?.some((ob1) => ob1?.title == props.breadcrumb[1]) &&
                props.breadcrumb[1].toLowerCase() == 'physical wellbeing'
            ) {
                let mainModuleI = SBList?.findIndex((ob) => {
                    return ob?.title == props?.breadcrumb[1];
                });
                if (mainModuleI != undefined && mainModuleI != -1) {
                    let subModuleI = SBList[mainModuleI]?.subNav?.findIndex((ob) => {
                        return ob?.title == props.breadcrumb[2];
                    });

                    if (subModuleI != undefined && subModuleI != -1) {
                        let subModuleI2 = SBList[mainModuleI]?.subNav[subModuleI].subNav2.findIndex((ob) => {
                            return ob?.title == option;
                        });

                        if (subModuleI2 != undefined && subModuleI2 != -1) {
                            history.push({
                                pathname: `/${urlSlug(props?.breadcrumb[1])}/${SBList[mainModuleI]?.subNav[subModuleI]?.title
                                    }/${option}`,
                                state: {
                                    reportName:
                                        SBList[mainModuleI]?.subNav[subModuleI]?.subNav2[subModuleI2]?.report_name
                                }
                            });
                        }
                    }
                }
            } else if (
                SBList?.some((ob) => ob?.subNav?.some((ob1) => ob1?.subNav2?.some((ob2) => ob2?.title == option)))
            ) {
                //will run for standard reports/product evaluation and modules with similar hierarchy
                let mainModuleI = SBList?.findIndex((ob) => {
                    return ob?.title == props?.breadcrumb[1];
                });

                if (mainModuleI != undefined && mainModuleI != -1) {
                    let subModuleI = SBList[mainModuleI]?.subNav?.findIndex((ob) => {
                        return ob?.subNav2?.some((o) => o?.title == option) && ob?.title == props?.breadcrumb[2];
                    });

                    if (subModuleI != undefined && subModuleI != -1) {
                        let subModuleI2 = SBList[mainModuleI]?.subNav[subModuleI].subNav2?.findIndex((ob) => {
                            return ob?.title == option;
                        });
                        if (subModuleI2 != undefined && subModuleI2 != -1) {
                            history.push({
                                pathname: `/${urlSlug(props?.breadcrumb[1])}/${SBList[mainModuleI]?.subNav[subModuleI]?.title
                                    }/${option}`,
                                state: {
                                    reportName:
                                        SBList[mainModuleI]?.subNav[subModuleI]?.subNav2[subModuleI2]?.report_name
                                }
                            });
                        }
                    }
                }
            } else if (SBList?.some((ob) => ob?.subNav?.some((ob1) => ob1?.title == option))) {
                let mainModuleI = SBList?.findIndex((ob) => {
                    return ob?.title == props?.breadcrumb[1];
                });

                if (mainModuleI != undefined && mainModuleI != -1) {
                    let subModuleI = SBList[mainModuleI]?.subNav?.findIndex((ob) => {
                        return ob?.title == option;
                    });

                    if (subModuleI != undefined && subModuleI != -1) {
                        history.push({
                            pathname: `/${urlSlug(props?.breadcrumb[1])}/${option}/${SBList[mainModuleI]?.subNav[subModuleI]?.subNav2[0]?.title
                                }`,
                            state: {
                                reportName: SBList[mainModuleI]?.subNav[subModuleI]?.subNav2[0]?.report_name
                            }
                        });
                    }
                }
            } else {
                // if (redirectCheck) {
                // history.push(`/home`);
                // props.saveBreadCrumbData(['Home']);
                // }
            }
        }
    }, [option]);

    if (!CheckAuth) {
        return <Redirect to="/home" />;
    }

    const showPopup = (str) => {
        setOpenModal(true);
        setField(str);
    };

    const hideModal = () => {
        setOpenModal(false);
    };

    // APP SKELETON
    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                height: '100vh',
                gridTemplateRows: 'auto auto 1fr auto',
                columnGap: '0',
                rowGap: '0',
                position: 'relative'
            }}
        >
            {/* ---------------------------- */}
            {/* HEADER */}
            {/* ---------------------------- */}
            <div style={{ gridColumn: '1 / span 2' }}>
                <AppHeader title={headerTitle} LogoutFunction={LogoutFunction} />
            </div>
            {/* ---------------------------- */}

            {/* ---------------------------- */}
            {/* SIDEBAR */}
            {/* ---------------------------- */}
            <div
                className="sidebar-content hideScroll"
                style={{ position: 'relative', overflowY: 'scroll', gridRow: 'span 3' }}
                ref={sidebarDOMRef}
            >
                <SideBar
                    contentCallback={contentCallback}
                    breadCrumb={props.breadcrumb}
                    option={option}
                    LogoutFunction={LogoutFunction}
                    SBList={SBList}
                    isLoading={isLoading}
                    sidebar={sidebar}
                    setSidebar={setSidebar}
                    showSidebar={showSidebar}
                />
            </div>
            {/* ---------------------------- */}
            {/* ---------------------------- */}
            {/* SIDEBAR NAV BUTTON */}
            {/* ---------------------------- */}
            <div
                onClick={showSidebar}
                style={{
                    zIndex: 1017,
                    position: 'absolute',
                    left: sidebarPosition.sidebarWidth + 'px',
                    top: sidebarPosition.sidebarHeightFromTop + 34 + 'px',
                    transform: 'translateX(-50%)',
                    cursor: 'pointer'
                }}
            >
                {/* Svgs */}
                {sidebar ? <CollapseNav /> : <ExpandVav />}
            </div>
            {/* ---------------------------- */}

            {/* ---------------------------- */}
            {/* BREADCRUMB */}
            {/* ---------------------------- */}
            <Breadcrumb history={history} data={props.breadcrumb} setLodsPages={setLodsPages} />
            {/* ---------------------------- */}

            {/* ---------------------------- */}
            {/* MAIN */}
            {/* ---------------------------- */}
            <main
                ref={domMainAreaElementRef}
                id="cmain"
                style={{ overflowY: 'scroll', backgroundColor: 'rgb(247, 247, 251)' }}
                className={disableLoader && 'disableLoaderNotifier'}
            >
                {isLoading && (
                    <div className="loader-div">
                        <ProgressSpinner />{' '}
                    </div>
                )}
                {props.screen}
                <ConfirmDialog
                    visible={visible}
                    onHide={() => setVisible(false)}
                    message="The data is not available at the moment"
                    icon="pi pi-exclamation-triangle"
                    accept={() => {
                        setVisible(false);
                        setDisableLoader(true);
                    }}
                    acceptLabel="Ok"
                    className="error-confirm-modal"
                    acceptClassName="confirm-accept-btn"
                    rejectClassName="confirm-reject-btn"
                />
            </main>
            {/* ---------------------------- */}

            {/* ---------------------------- */}
            {/* FOOTER */}
            {/* ---------------------------- */}
            <AppFooter scrollBarIsAtTheEnd={scrollBarIsAtTheEnd} />
            {/* ---------------------------- */}
        </div>
    );
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
    userUsageData: state.user.userUsageData,
    breadcrumb: state.breadcrumb.breadCrumbData,
    sideBarRefresh: state.sideBarData.sideBarRefresh
});

export default connect(mapStateToProps, {
    saveBreadCrumbData,
    saveUserUsageData,
    saveDataFactorySelectedTab,
    saveFromMyFav,
    saveSidebarToggle
})(AppPortal);
