import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getSilentAccessToken } from './ms-helper';
import Emitter from './event-emitter';
import { userAgentApplication } from './common-helper';
export { axios };
const currentEnvironment = window.currentEnvironment;

axiosRetry(axios, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 1000; // time interval between retries
    },

    // Retry in a particular situation. Example: retry only if the status code is in between 500-599.
    retryCondition: (error) => {
        return error?.response?.status >= 500 && error?.response?.status <= 599;
    }
});

let myInterceptor;

function getHostName(hostVersion) {
    if (hostVersion == 'v2') {
        if (currentEnvironment === 'LOCAL') {
            // return 'https://ssa-backend.azurewebsites.net'; // Ideally should be LOCAL === DEV
            return 'https://ssa-uat01.azurewebsites.net'; //Sub-optimally set LOCAL === UAT
        } else if (currentEnvironment === 'DEV' || currentEnvironment === 'DEVLOCAL') {
            return 'https://sie-backend-dev.azurewebsites.net';
        } else if (currentEnvironment === 'DEV2') {
            return 'https://ssa-uat01.azurewebsites.net';
        } else if (currentEnvironment === 'UAT') {
            return 'https://ssa-uat01.azurewebsites.net';
        } else if (currentEnvironment === 'DEMO' || currentEnvironment === 'DEMOLOCAL') {
            return 'https://ssa-demo-prod.azurewebsites.net';
        } else if (currentEnvironment === 'PROD' || currentEnvironment === 'PRODLOCAL' || currentEnvironment === 'PRODNEW') {
            return 'https://ssa-prod-backend01.azurewebsites.net';
        }
    } else {
        if (currentEnvironment === 'LOCAL') {
            // return 'https://app-sie-dash-006.azurewebsites.net'; // Ideally should be LOACAL === DEV
            return 'https://sie-uat-cmcst-backend.azurewebsites.net'; // Sub-optimally set LOCAL === UAT
        } else if (currentEnvironment === 'DEV' || currentEnvironment === 'DEVLOCAL') {
            return 'https://app-sie-dash-006.azurewebsites.net';
        } else if (currentEnvironment === 'DEV2') {
            return 'https://sie-uat-cmcst-backend.azurewebsites.net';
        } else if (currentEnvironment === 'UAT') {
            return 'https://sie-uat-cmcst-backend.azurewebsites.net';
        } else if (currentEnvironment === 'DEMO' || currentEnvironment === 'DEMOLOCAL') {
            return 'https://sie-demo-backend.azurewebsites.net';
        } else if (currentEnvironment === 'PROD' || currentEnvironment === 'PRODLOCAL' || currentEnvironment === 'PRODNEW') {
            return 'https://sie-comcast-backend.azurewebsites.net';
        }
    }
}

async function getToken() {
    let token = await getSilentAccessToken(userAgentApplication);
    return token;
}

if (!myInterceptor) {
    myInterceptor = axios.interceptors.request.use(
        async (config) => {
            config.timeout = 2.5 * 60 * 1000; // 0.5 min
            const token = await getToken();
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            // config.headers['Content-Type'] = 'application/json';
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        (response) => {
            return Promise.resolve(response);
        },
        (error) => {
            if (error?.response?.status >= 500 && error?.response?.status <= 599) {
                Emitter.emit('ERROR_DATA_NOT_AVAILABLE', error.response.status);
            }
            return Promise.reject(error);
        }
    );
}

export function searchLink(body) {
    return axios.post(`${getHostName()}/search_link`, body);
}

export function postAccessRole(body) {
    return axios.post(`${getHostName()}/tab_access`, body);
}
export function getDataFactory(body) {
    return axios.get(`${getHostName()}/data_factory`, body);
}

export function getDFCuratedData() {
    return axios.get(`${getHostName()}/data_factory_home/curated_status_data`);
}

export function getDFCuratedDataDownload() {
    return axios.get(`${getHostName()}/data_factory_home/curated_status_data/download`);
}

export function getDFProductionReportStatus() {
    return axios.get(`${getHostName()}/data_factory_home/production_report_status`);
}

export function getDFProductionReportStatusDownload() {
    return axios.get(`${getHostName()}/data_factory_home/production_report_status/download`);
}

export function getDFProductionReportStatusHover() {
    return axios.get(`${getHostName()}/data_factory_home/production_report_status/hover`);
}

export function addToMyFavrourites(body) {
    return axios.post(`${getHostName('v2')}/v2/addBookmark`, body);
}

export function removeFromMyFavrourites(body) {
    return axios.post(`${getHostName('v2')}/v2/removeBookmark`, body);
}

export function getMyFavrourites(body) {
    return axios.post(`${getHostName('v2')}/v2/myFavorites`, body);
}

export function getDetailedReport(pgSize, currentPage, body, parameter) {
    return axios.post(`${getHostName()}/detailed_report?pg=${currentPage}&pgsz=${pgSize}${parameter}`, body);
}

export function downloadDetailedReport(body, parameter) {
    return axios.get(
        `${getHostName()}/detailed_report/download?pg=${body.currentPage}&pgsz=${body.pageSize}&typ=${body.typ
        }&sub_typ=${body.sub_typ}&month=${body.month}${parameter}`,
        body
    );
}

export function activityTracker() {
    return axios.get(`${getHostName()}/data_factory_home/activity_tracker`);
}

export function activityTrackerDownload(parameter) {
    return axios.get(`${getHostName()}/data_factory_home/activity_tracker/download${parameter}`);
}

export function getVendorOverview(body) {
    return axios.post(`${getHostName()}/overview/description`, body);
}

export function getLayoutDetails(body) {
    return axios.post(`${getHostName()}/overview/table`, body);
}

export function getLayoutDetailsDownload(body) {
    return axios.get(`${getHostName()}/overview/table/download?src=${body.src}&typ=${body.typ}`);
}

export function getMasterData(currentPage, pgSize, parameter) {
    return axios.get(`${getHostName()}/master_data_sets?Pg=${currentPage}&Pgsz=${pgSize}${parameter}`);
}

export function downloadMasterData(currentPage, pgSize, parameter) {
    return axios.get(`${getHostName()}/master_data_sets/download?Pg=${currentPage}&Pgsz=${pgSize}${parameter}`);
}

export function getQnas(body) {
    return axios.post(`${getHostName()}/Q_A`, body);
}

export function getStandardReports() {
    return axios.post(`${getHostName()}/standard_report_home`);
}

export function getStandardReportsOverview(body) {
    return axios.post(`${getHostName()}/standard_report/overview`, body);
}

export function getOverview(body) {
    return axios.post(`${getHostName()}/overview`, body);
}

export function getListOfDataSourceTable(endPoint, currentPage, parameter, body, pageSize) {
    return axios.post(`${getHostName()}${endPoint}?pg=${currentPage}&pgsz=${pageSize}${parameter}`, body);
}

export function exportListOfDataSourceTable(endPoint, parameter) {
    return axios.get(`${getHostName()}${endPoint}${parameter}`);
}

export function getSampleRecords(endPoint, currentPage, parameter, body) {
    return axios.post(`${getHostName()}${endPoint}?pg=${currentPage}&pgsz=20${parameter}`, body);
}

export function getProductionReportOverview(currentPage, parameter, body) {
    return axios.post(`${getHostName()}/production_report/overview?pg=${currentPage}&pgsz=10${parameter}`, body);
}

export function getProductionReportKPI(body) {
    return axios.post(`${getHostName()}/production_report/point_in_time/kpi`, body);
}

export function getProductionReportFilter() {
    return axios.get(`${getHostName()}/production_report/point_in_time/filter`);
}

export function getProductionReportTable(currentPage, parameter, body) {
    return axios.post(
        `${getHostName()}/production_report/point_in_time/table?pg=${currentPage}&pgsz=10${parameter}`,
        body
    );
}

export function getProductionReportTrendOverTime(body) {
    return axios.post(`${getHostName()}/production_report/trend_over_time`, body);
}

export function getStandardReportsMedicalDemographic(parameter) {
    return axios.get(`${getHostName()}/medical/standard_reports/demographic${parameter}`);
}

export function getStandardReportsMedicalUtilization(parameter) {
    return axios.get(`${getHostName()}/medical/standard_reports/utilization${parameter}`);
}

export function getStandardReportsPharmacyDemographic(parameter) {
    return axios.get(`${getHostName()}/pharmacy/standard_reports/demographic${parameter}`);
}

export function getStandardReportsPharmacyUtilization(parameter) {
    return axios.get(`${getHostName()}/pharmacy/standard_reports/utilization${parameter}`);
}

export function getLimitations(body) {
    return axios.post(`${getHostName()}/limitations`, body);
}

export function getLimitationsForVendor(body) {
    return axios.post(`${getHostName()}/overview/limitations`, body);
}

export function getQnAsForVendor(body) {
    return axios.post(`${getHostName()}/overview/faqs`, body);
}

export function getLayout(body) {
    return axios.post(`${getHostName()}/layout`, body);
}

export function getLayoutTableData(body, page, pageSize, parameter) {
    return axios.post(`${getHostName()}/tableforlayout?pg=${page}&pgsz=${pageSize}${parameter}`, body);
}

export function getHealthcareReportData(body) {
    return axios.post(`${getHostName()}/healthcare_report`, body);
}
// export function getLayoutTableData(body, page, pageSize, parameter) {
//   return axios.post(`${getHostName()}/tableforlayout?pg=${page}&pgsz=${pageSize}${parameter}`, body);
// }

export function exportLayoutTableData(parameter) {
    return axios.get(`${getHostName()}/tableforLayout/download${parameter}`);
}

export function getQualityReportKpi(body) {
    return axios.post(`${getHostName()}/production_report/point_in_time/kpi`, body);
}

export function getQualityReportFilters(params) {
    return axios.get(
        `${getHostName()}/production_report/point_in_time/filter?src=${params?.src}&layout=${params?.layout}`
    );
}

export function getQualityReportTableData(body, page, pageSize, parameter) {
    return axios.post(
        `${getHostName()}/production_report/point_in_time/table?pg=${page}&pgsz=${pageSize}${parameter}`,
        body
    );
}

export function exportQualityReportsTableData(parameter) {
    return axios.get(`${getHostName()}/production_report/point_in_time/table/download${parameter}`);
}

export function getQualityReportSummaryTableData(body, page, pageSize, parameter) {
    return axios.post(`${getHostName()}/quality_report/summary?pg=${page}&pgsz=${pageSize}${parameter}`, body);
}

export function exportSummaryTableData(parameter) {
    return axios.get(`${getHostName()}/quality_report/summary/download${parameter}`);
}

export function getEddLayout(body, endPoint) {
    return axios.post(`${getHostName()}${endPoint}`, body);
}

export function exportEddLayout(parameter, endPoint) {
    return axios.get(`${getHostName()}${endPoint}${parameter}`);
}

export function requestService(body, parameters) {
    return axios.post(`${getHostName()}/Request_service${parameters}`, body);
}

export function raiseBug(body) {
    return axios.post(`${getHostName('v2')}/api/v1/sendEmailToSupportCenter`, body);
}

export function activityTrackerSearch(parameter) {
    return axios.get(`${getHostName()}/data_factory_home/activity_tracker${parameter}`);
}

export function getProductEvaluationHome() {
    return axios.get(`${getHostName()}/program_evaluation_home`);
}
export function saveUserUsageRecord(body) {
    return axios.post(`${getHostName('v2')}/v2/Usagereport`, body);
}

export async function getSubModules(endpoint, parameter) {
    if (endpoint != null && endpoint != 'null' && endpoint != '') {
        if (parameter) {
            return axios.post(`${getHostName()}/${endpoint}`, parameter);
        } else {
            return axios.get(`${getHostName()}/${endpoint}`);
        }
    }
}

export function getMasterDataTable(body, page, pageSize, parameter) {
    return axios.post(`${getHostName()}/master_data_dictionary?pg=${page}&pgsz=${pageSize}${parameter}`, body);
}

export function exportMasterDataTable(parameter) {
    return axios.get(`${getHostName()}/master_data_dictionary/download${parameter}`);
}

export function exportPowerbiReportSection() {
    return axios.get(`${getHostName()}/embed_powerbi/reportSection`);
}

export function exportPowerbiTest() {
    return axios.get(`${getHostName()}/embed_powerbi_report`);
}

export function exportPowerBiReport(format, reportId, groupId, state, pageName, reportName) {
    return new Promise((resolve, reject) => {
        let url = `${getHostName()}/export_bi_download?format=${format}&report_id=${reportId}&workspace_id=${groupId}&state=${encodeURIComponent(
            state
        )}&subModule=${reportName}`;

        url = url + '&pageName=' + pageName;

        const requestOptions = {
            method: 'GET'
        };
        fetch(url, requestOptions)
            .then((response) => response.blob())
            .then((data) => {
                console.log('Resolved data', data);
                resolve(data);
            })
            .catch((error) => {
                console.log('Rejected error=>', error);
                reject(error);
            });
    });
}

export function getLoginHistory() {
    return axios.get(`${getHostName('v2')}/api/v1/getHistory`);
}

export function getLocationFromLatLong(query) {
    return axios.get(
        `https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&query=${query}&auth-type=subscription-key&subscription-key=q3Kh5MHLg7vn50kPTp9vZRLKXpxyTGBrhxmvhIu8UQU`
    );
}

export function getAccessLevelData(body) {
    return axios.post(`${getHostName()}/access_level`, body);
}

export function getAccessViewCount(body) {
    return axios.post(`${getHostName()}/access_usage_count`, body);
}

export function updateLoginData(body) {
    return axios.post(`${getHostName('v2')}/api/v1/addHistory`, body);
}

export function checkCluster() {
    return axios.get(`${getHostName('v2')}/v2/warehouseState`);
}
