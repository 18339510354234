import CryptoJS from 'crypto-js';
import { getLocationFromLatLong } from './api-helper';
import * as localStorage from './local-storage-helper';

const currentEnvironment = window.currentEnvironment;

const getClientID = () => {
    // if (currentEnvironment === 'LOCAL') return 'f20167c9-2574-4427-996a-c2d9c3b3b7ea'; // Ideally should be LOCAL === DEV
    if (currentEnvironment === 'LOCAL')
        return '7e16ea51-dafc-4805-9573-b7b03d846163'; // Sub-optimally set LOCAL === UAT
    else if (currentEnvironment === 'DEV') return 'f20167c9-2574-4427-996a-c2d9c3b3b7ea';
    else if (currentEnvironment === 'DEV2') return '7e16ea51-dafc-4805-9573-b7b03d846163';
    else if (currentEnvironment === 'UAT') return '7e16ea51-dafc-4805-9573-b7b03d846163';
    else if (currentEnvironment === 'DEMO') return '21639355-f469-4602-8e4b-fe7ab0e85568';
    else if (currentEnvironment === 'DEMOLOCAL') return '21639355-f469-4602-8e4b-fe7ab0e85568';
    else if (currentEnvironment === 'PROD') return 'ee9e550c-c114-4bae-88a8-d08dcf65cfa2';
    else if (currentEnvironment === 'PRODNEW') return 'ee9e550c-c114-4bae-88a8-d08dcf65cfa2';
    else if (currentEnvironment === 'PRODLOCAL')
        return 'f20167c9-2574-4427-996a-c2d9c3b3b7ea'; //This shouldn't have been separate from PROD but it is.
    else if (currentEnvironment === 'DEVLOCAL') return 'f20167c9-2574-4427-996a-c2d9c3b3b7ea'; //This shouldn't have been separate.
};

const getRedirectURI = () => {
    if (['LOCAL', 'PRODLOCAL', 'DEVLOCAL', 'DEMOLOCAL'].includes(currentEnvironment))
        return `http://localhost:${window.location.port}`;
    else if (currentEnvironment === 'DEV') return 'https://app-sie-008.azurewebsites.net';
    else if (currentEnvironment === 'DEV2') return 'https://sie1dev.azurewebsites.net';
    else if (currentEnvironment === 'UAT') return 'https://sie-uat-cmcst-frontend.azurewebsites.net';
    else if (currentEnvironment === 'DEMO') return 'https://siedemo.cognitioanalytics.com';
    else if (currentEnvironment === 'PROD') return 'https://legacysie.cognitioanalytics.com/';
    else if (currentEnvironment === 'PRODNEW') return 'https://legacysie.cognitioanalytics.com';
};

const getResourceID = () => {
    // if (currentEnvironment === 'LOCAL') return '4b38d133-9d1f-4cab-a782-950abf19ba5f'; // Ideally should be LOCAL === DEV
    if (currentEnvironment === 'LOCAL')
        return '1c83c5fc-4066-425a-8046-c795c9fb0703'; // Sub-optimally set LOCAL === UAT
    else if (currentEnvironment === 'DEV' || currentEnvironment === 'DEVLOCAL')
        return '4b38d133-9d1f-4cab-a782-950abf19ba5f';
    else if (currentEnvironment === 'DEV2') return '1c83c5fc-4066-425a-8046-c795c9fb0703';
    else if (currentEnvironment === 'UAT') return '1c83c5fc-4066-425a-8046-c795c9fb0703';
    else if (currentEnvironment === 'DEMO' || currentEnvironment === 'DEMOLOCAL')
        return '23a610db-47aa-4a21-a296-84dc5f2385b2';
    else if (currentEnvironment === 'PROD' || currentEnvironment === 'PRODLOCAL' || currentEnvironment === 'PRODNEW')
        return '430ac582-609a-4950-b0c4-a71fc03ba2eb';
};

export const config = {
    CLIENT_ID: getClientID(),
    TENEANT_ID: 'dde4ce25-c4d3-4ffb-98ad-d5a08b940f40',
    AUTHORITY: 'https://login.microsoftonline.com/dde4ce25-c4d3-4ffb-98ad-d5a08b940f40',
    REDIRECT_URI: getRedirectURI(),
    SCOPES: ['openid']
};

export let resourceId = getResourceID();

export function normalizeError(error) {
    var normalizedError = {};
    if (typeof error === 'string') {
        var errParts = error.split('|');
        normalizedError = errParts.length > 1 ? { message: errParts[1], debug: errParts[0] } : { message: error };
    } else {
        normalizedError = {
            message: error.message,
            debug: JSON.stringify(error)
        };
    }
    return normalizedError;
}

async function getAccessToken(userAgentApplication) {
    try {
        let silentResult = await userAgentApplication.acquireTokenSilent({
            scopes: ['user.read']
        });
        // console.log("aa ---------- silentResult=>", silentResult);
        return silentResult.accessToken;
    } catch (err) {
        if (isInteractionRequired(err)) {
            let interactiveResult = await userAgentApplication.acquireTokenPopup({
                scopes: ['user.read']
            });
            // console.log("aa ---------- interactiveResult=>", interactiveResult);
            return interactiveResult.accessToken;
        } else {
            return null;
        }
    }
}

export async function getSilentAccessToken(userAgentApplication) {
    try {
        let silentResult = await userAgentApplication.acquireTokenSilent({
            scopes: [config.CLIENT_ID]
        });
        // console.log("aa ---------- silentResult=>", silentResult);
        return silentResult?.idToken?.rawIdToken;
    } catch (err) {
        if (isInteractionRequired(err)) {
            let interactiveResult = await userAgentApplication.acquireTokenPopup({
                scopes: [config.CLIENT_ID]
            });
            // console.log("aa ---------- interactiveResult=>", interactiveResult);
            return interactiveResult?.idToken?.rawIdToken;
        } else {
            return null;
        }
    }
}

function isInteractionRequired(error) {
    if (!error.message || error.message.length <= 0) {
        return false;
    }
    return (
        error.message.indexOf('consent_required') > -1 ||
        error.message.indexOf('interaction_required') > -1 ||
        error.message.indexOf('login_required') > -1 ||
        error.message.indexOf('no_account_in_silent_request') > -1
    );
}

var graph = require('@microsoft/microsoft-graph-client');

function getAuthenticatedClient(accessToken) {
    const client = graph.Client.init({
        authProvider: (done) => {
            done(null, accessToken);
        }
    });

    return client;
}

//////////////////////////////////////////////
// Fetches the list of members from various groups to determine shareable list of users
//////////////////////////////////////////////
export const getSSAGroupUsers = async (userAgentApplication) => {
    try {
        let accessToken = await getAccessToken(userAgentApplication);
        const client = getAuthenticatedClient(accessToken);
        const { displayName: myDisplayName } = JSON.parse(localStorage.get('USER_DETAILS'));
        let listOne = null;
        let listTwo = null;
        let SSA_users = [];

        //----------------
        // Obtaining the correct list(s) of members
        //----------------
        let internalGroupID = 'f995d18d-f368-4715-873d-2943c315ae5d';
        let externalGroupID = '70ad0e61-609d-4427-b637-b481d7de40b7';

        if (['DEMO', 'DEMOLOCAL', 'DEV', 'DEV2', 'DEVLOCAL', 'UAT', 'LOCAL'].includes(currentEnvironment)) {
            externalGroupID = null;
        }

        if (internalGroupID && externalGroupID) {
            const internalMemberList = await client.api(`/groups/${internalGroupID}/members`).get();
            const externalMemberList = await client.api(`/groups/${externalGroupID}/members`).get();

            const myObjInInternalGroup = internalMemberList.value.find(
                (userObj) => userObj.displayName === myDisplayName
            );
            const myObjInExtrnalGroup = externalMemberList.value.find(
                (userObj) => userObj.displayName === myDisplayName
            );

            if (myObjInInternalGroup && myObjInExtrnalGroup) {
                listOne = internalMemberList.value;
                listTwo = externalMemberList.value;
            } else if (myObjInInternalGroup) {
                listOne = internalMemberList.value;
            } else if (myObjInExtrnalGroup) {
                listOne = externalMemberList.value;
            }
        } else if (internalGroupID) {
            const internalMemberList = await client.api(`/groups/${internalGroupID}/members`).get();

            const myObjInInternalGroup = internalMemberList.value.find(
                (userObj) => userObj.displayName === myDisplayName
            );

            if (myObjInInternalGroup) {
                listOne = internalMemberList.value;
            }
        } else if (externalGroupID) {
            const externalMemberList = await client.api(`/groups/${externalGroupID}/members`).get();

            const myObjInExtrnalGroup = externalMemberList.value.find(
                (userObj) => userObj.displayName === myDisplayName
            );

            if (myObjInExtrnalGroup) {
                listOne = externalMemberList.value;
            }
        }
        //----------------

        //----------------
        // Determining the correct members
        //----------------
        if (listOne && listTwo) {
            const listToReturn = [...listOne];
            const listToReturnMails = listToReturn.map((user) => {
                return user.mail;
            });

            listTwo.forEach((user) => {
                if (listToReturnMails.includes(user.mail)) return;

                listToReturn.push(user);
            });

            SSA_users = listToReturn;
        } else if (listOne) {
            listOne.forEach((userObj) => {
                SSA_users.push(userObj);
            });
        }
        //----------------

        //----------------
        // Return the list of members to display
        //----------------
        return SSA_users;
        //----------------
    } catch (e) {
        return console.error(e);
    }
};
//////////////////////////////////////////////

//////////////////////////////////////////////
// Gets the basic user details as well as his group details.
// Saves the relevant IDs in the localStorage upon fetching the details.
//////////////////////////////////////////////
//////////////////////////////////////////////
export async function getUserDetails(userAgentApplication) {
    try {
        // TODO: DELETE THIS
        console.log('INSIDE getUserDetails 240');
        let accessToken = await getAccessToken(userAgentApplication);
        const client = getAuthenticatedClient(accessToken);
        // TODO: DELETE THIS
        console.log('INSIDE getUserDetails 243');

        //----------------
        // Fetch and store user details
        //----------------
        const user = await client
            .api('/me')
            .select(
                'businessPhones,displayName,country,mobilePhone,city,givenName,id,jobTitle,mail,streetAddress,usageLocation,officeLocation,preferredLanguage,surname,userPrincipalName,state'
            )
            .get();

        localStorage.set('USER_DETAILS', JSON.stringify(user));
        console.log('INSIDE getUserDetails 257: ', user);
        //----------------

        //----------------
        // ????
        //----------------
        return appRoleAssignments(accessToken, user?.id);
        //----------------
    } catch (err) {
        // TODO: DELETE THIS
        console.log('INSIDE getUserDetails CATCH BLOCK');
        return null;
    }
}

export async function getLastTenSessions(userAgentApplication) {
    try {
        let accessToken = await getAccessToken(userAgentApplication);

        const client = getAuthenticatedClient(accessToken);
        // console.log(137, client);
        const signInData = await client
            .api('/auditLogs/signIns/')
            .filter("startsWith(appDisplayName,'Graph')")
            .top(10)
            .get();
        // console.log(signInData);
        return signInData;
    } catch (e) {
        // console.log('cathc err', e);
        return null;
    }

    // sessionStorage.setItem('L')
}

export async function appRoleAssignments(accessToken, userId) {
    try {
        const client = getAuthenticatedClient(accessToken);
        const user = await client
            .api(`/users/${userId}/appRoleAssignments`)
            .filter(`resourceId eq ${resourceId}`)
            .get();
        let role = [];
        if (user?.value?.length) {
            user?.value?.forEach((data) => {
                if (data?.principalType == 'Group') {
                    role.push(data?.principalDisplayName);
                    return;
                }
            });
        }
        return role;
    } catch (err) {
        return null;
    }
}

export function encryptDecryptString(userRole, isEnc) {
    if (!userRole) {
        return '';
    }
    const passphrase = 'cognitio';
    if (isEnc) {
        return CryptoJS.AES.encrypt(userRole, passphrase).toString();
    } else {
        const bytes = CryptoJS.AES.decrypt(userRole, passphrase);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}

export const getLongitudeLatitude = () => {
    const locationSuccess = async (position) => {
        let lat = position.coords.latitude;
        let long = position.coords.longitude;
        let query = `${lat},${long}`;
        let res = await getLocationFromLatLong(query);

        if (sessionStorage.getItem('LOCATION') === null) {
            let obj = {};
            if (res.data) {
                let state = res.data.addresses[0].address.countrySubdivision;
                let city = res.data.addresses[0].address.municipality;

                obj['state'] = state;
                obj['city'] = city;
                sessionStorage.setItem('LOCATION', JSON.stringify(obj));
            }
            // console.log(obj)
            // return obj;
        }
        return res.data;
    };

    const locationError = () => {
        return false;
    };

    if (navigator) {
        navigator.geolocation.getCurrentPosition(locationSuccess, locationError);
    }
};

export function fnBrowserDetect() {
    let agent = navigator.userAgent.toLocaleLowerCase();
    switch (true) {
        case agent.indexOf('edge') > -1:
            return 'edge';
        case agent.indexOf('edg') > -1:
            return 'chromium based edge (dev or canary)';
        case agent.indexOf('opr') > -1 && !!window.opr:
            return 'opera';
        case agent.indexOf('chrome') > -1 && !!window.chrome:
            return 'chrome';
        case agent.indexOf('trident') > -1:
            return 'ie';
        case agent.indexOf('firefox') > -1:
            return 'firefox';
        case agent.indexOf('safari') > -1:
            return 'safari';
        default:
            return 'other';
    }
}

export async function getGroupDetails(userAgentApplication) {
    try {
        let accessToken = await getAccessToken(userAgentApplication);
        const client = getAuthenticatedClient(accessToken);
        let groupList = await client
            .api('/groups?$expand=appRoleAssignments')
            .filter(`startswith(displayName, \'SIE\')`)
            .get();

        groupList = groupList?.value?.filter((grp) => {
            return (
                grp.appRoleAssignments?.length &&
                grp.appRoleAssignments?.some((o2) => o2['resourceId']?.includes(resourceId))
            );
        });

        let promises = groupList?.map(async (grp) => {
            let members = await client.api(`/groups/${grp?.id}/members`).get();
            // console.log('MEMBERS---',members)
            // console.log("getGroupDetails :: grp :: ", grp);
            members?.value.forEach((ele) => {
                // console.log("getGroupDetails :: members :: ele :: ", ele);
                ele['groupId'] = grp?.id;
                ele['groupDescription'] = grp?.description;
                ele['groupDisplayName'] = grp?.displayName;
                ele['isSubModule'] = grp?.description ? grp?.description.includes('_') : false;
                ele['subModuleName'] = [];
            });

            let grpObj = {
                group: grp,
                membersList: members?.value,
                isSubModule: grp?.description ? grp?.description.includes('_') : false
            };

            // console.log("getGroupDetails :: grpObj :: ", grpObj);
            return grpObj;
        });
        const finalList = await Promise.all(promises);
        // console.log("getGroupDetails :: finalList :: ", finalList);
        return finalList;
    } catch (err) {
        console.log('Group and members list error =>', err);
        return null;
    }
}

export async function addMember(userAgentApplication, dirObj, id) {
    try {
        // console.log('HIIIIIIIIIIIII');
        let accessToken = await getAccessToken(userAgentApplication);
        const client = getAuthenticatedClient(accessToken);
        const directoryObject = {
            '@odata.id': `https://graph.microsoft.com/v1.0/directoryObjects/${dirObj}`
        };
        // console.log(dirObj,":: directoryObject", id,":: ID");
        await client.api(`/groups/${id}/members/$ref`).post(directoryObject);
    } catch (err) {
        console.error('ERROR =>', err);
        return null;
    }
}

export async function deleteMember(userAgentApplication, groupID, directroyObjID) {
    try {
        let accessToken = await getAccessToken(userAgentApplication);
        const client = getAuthenticatedClient(accessToken);
        // await client.api(`/groups/${groupID}/members/${directroyObjID}/$ref`).delete();
        console.log(directroyObjID, ':: directoryObject', groupID, ':: ID');
    } catch (err) {
        console.error('ERROR =>', err);
        return null;
    }
}
