import { axios } from './api-helper';
const currentEnvironment = window.currentEnvironment;

function getHostName() {
    if (currentEnvironment === 'LOCAL') {
        // return 'https://ssa-backend.azurewebsites.net'; //Ideally should be LOCAL === DEV
        return 'https://ssa-uat01.azurewebsites.net'; // Sub-optimally set LOCAL === UAT
    } else if (currentEnvironment === 'DEV' || currentEnvironment === 'DEVLOCAL') {
        return 'https://sie-backend-dev.azurewebsites.net';
    } else if (currentEnvironment === 'DEV2') {
        return 'https://ssa-uat01.azurewebsites.net';
    } else if (currentEnvironment === 'UAT') {
        return 'https://ssa-uat01.azurewebsites.net';
    } else if (currentEnvironment === 'DEMO' || currentEnvironment === 'DEMOLOCAL') {
        return 'https://ssa-demo-prod.azurewebsites.net';
    } else if (currentEnvironment === 'PROD' || currentEnvironment === 'PRODLOCAL' || currentEnvironment === 'PRODNEW') {
        return 'https://ssa-prod-backend01.azurewebsites.net';
    }
}

export function getInfoKiosk(body) {
    return axios.post(`${getHostName()}/api/v1/infokiosk`, body);
}

export function getStatCardData() {
    return axios.get(`${getHostName()}/api/v2/getCardData`);
}

export function getAllFiltersData() {
    return axios.get(`${getHostName()}/api/v1/getAllFilters`);
}

export function deleteFilter(req) {
    return axios.post(`${getHostName()}/api/v1/deleteFilters`, req);
}

export function getMetricsTabData(req) {
    return axios.post(`${getHostName()}/api/v2/getDataMetricsNew`, req);
}

export function getTimePeriodTabData() {
    return axios.get(`${getHostName()}/api/v1/getDateSelection`);
}

export function getGranularityTabData(req) {
    return axios.post(`${getHostName()}/api/v2/getGranularitySelection`, req);
}

export function getMemberSelectionTabHeaders(req) {
    return axios.post(`${getHostName()}/api/v2/getMemberSelection`, req);
}

export function getInformationDescription() {
    return axios.get(`${getHostName()}/api/v1/getdescription`);
}

export function getMemberSelectionTabData(body) {
    return axios.post(`${getHostName()}/api/v2/loadFilters`, body);
}

export function getFinalQueryReport(body) {
    return axios.post(`${getHostName()}/api/v1/getReportQueryBuilder`, body);
}

export function getAllStoreFilters(body) {
    return axios.post(`${getHostName()}/api/v1/storeFilter`, body);
}

export function getReportAnalysis(body) {
    return axios.post(`${getHostName()}/api/v2/getReportAnalysis`, body);
}
export function deleteReportAnalysis(body) {
    return axios.post(`${getHostName()}/api/v2/deleteReportAnalysis`, body);
}
export function updateReportAnalysisName(body) {
    return axios.post(`${getHostName()}/api/v2/updateReportAnalysisName`, body);
}
export function getStartAndEndDate(body) {
    return axios.post(`${getHostName()}/api/v1/getStartAndEndDate`, body);
}
export function updateExportDate(body) {
    return axios.post(`${getHostName()}/api/v2/updateExportDate`, body);
}
export function getLastUpdateDate() {
    return axios.get(`${getHostName()}/api/v1/getLastUpdateDate`);
}
export function runReportAnalysis(body) {
    return axios.post(`${getHostName()}/api/v2/runReportAnalysis`, body);
}
export function getCohortInfo() {
    return axios.get(`${getHostName()}/api/v2/getCohortsData`);
}
export function npiDataValidate(body) {
    return axios.post(`${getHostName()}/api/v1/npiDataValidate`, body);
}
export function sharedwithMeTable(body) {
    return axios.get(`${getHostName()}/api/v1/getSharedWithMeAnalysis`);
}
export function sharedwithMeRemoveAnalysis(body) {
    return axios.post(`${getHostName()}/api/v1/removeSharedReportAnalysis`, body);
}
export function editSharedAnalysis(req) {
    return axios.post(`${getHostName()}/api/v1/editSharedReportAnalysis`, req);
}
export function shareAnalysisReport(body) {
    return axios.post(`${getHostName()}/api/v1/storeSharedReportAnalysis`, body);
}
export function getCurrentReportUsers(body) {
    return axios.post(`${getHostName()}/api/v1/getMyAnalysisCurrentUsers`, body);
}
export function getGroupData() {
    return axios.get(`${getHostName()}/api/v1/getGroups`);
}
export function getGroupDetailsById(body) {
    return axios.post(`${getHostName()}/api/v1/getGroupById`, body);
}
export function createGroup(body) {
    return axios.post(`${getHostName()}/api/v1/createGroup`, body);
}
export function deleteGroup(body) {
    return axios.post(`${getHostName()}/api/v1/deleteGroup`, body);
}
export function updateGroup(body) {
    return axios.post(`${getHostName()}/api/v1/updateGroup`, body);
}
export function getAnalysisByGroupId(body) {
    return axios.post(`${getHostName()}/api/v1/getAnalysisByGroupId`, body);
}
export function getAllNotifications() {
    return axios.get(`${getHostName()}/api/v1/getAllNotifications`);
}
export function acceptRejectRequest(body) {
    return axios.post(`${getHostName()}/api/v1/acceptRejectRequest`, body);
}
export function deleteNotification(body) {
    return axios.post(`${getHostName()}/api/v1/deleteNotification`, body);
}
export function updateSeenStatus(body) {
    return axios.post(`${getHostName()}/api/v1/updateSeenStatus`, body);
}
