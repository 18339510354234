import React, { useEffect, useState } from 'react';
import {
    getVendorOverview,
    getLayoutDetails,
    getLayoutDetailsDownload,
    getDFProductionReportStatus
} from '../../../utils/api-helper';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ReactComponent as IC_Name } from '../../../assets/images/name.svg';
import { ReactComponent as IC_PhoneNumber } from '../../../assets/images/phone.svg';
import Down from '../../../assets/images/vendor-accordion-icons/expanded.svg';
import Up from '../../../assets/images/vendor-accordion-icons/default.svg';
import Email from '../../../assets/images/vendor-profile-icons/email.svg';
import Phone from '../../../assets/images/vendor-profile-icons/mobile.svg';
import SortImg from '../../../assets/images/sort.svg';
import SortDescImg from '../../../assets/images/sortdesc.svg';
import ExportImg from '../../../assets/images/export.svg';
import DataTable from '../datatable';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import { Link } from 'react-router-dom';
import sortObjectsArray from 'sort-objects-array';
import Limitation from '../limitations/index';
import Qnas from '../qna/index';
import { connect } from 'react-redux';
import './style.css';
import DevClientLogo from '../../../assets/images/xyz_company.png';
import ClientLogo from '../../../assets/images/client-logo.jpg';
// import Crossover from './IconsVendors/crossover.svg'
import ModuleCard from './ModuleCard';
import ContactDetailsCard from './ContactDetailsCard';
import accolade from '../../../assets/IconsVendors/accolade.svg';
import cigna from '../../../assets/IconsVendors/cigna.svg';
import compsych from '../../../assets/IconsVendors/ComPsych-new.svg';
import crossover from '../../../assets/IconsVendors/crossover.svg';
import cvs from '../../../assets/IconsVendors/cvs.svg';
import fidelity from '../../../assets/IconsVendors/fidelity.svg';
import grandrounds from '../../../assets/IconsVendors/grandrounds.svg';
import healthquity from '../../../assets/IconsVendors/health equity.svg';
import independence from '../../../assets/IconsVendors/ibc.svg';
import kaiser from '../../../assets/IconsVendors/Kaiser 1.svg';
import quest from '../../../assets/IconsVendors/quest.svg';
import sedwick from '../../../assets/IconsVendors/Logo.svg';
import tufts from '../../../assets/IconsVendors/tufts.svg';
import includedhealth from '../../../assets/IconsVendors/includedhealth.svg';
import unitedconcordia from '../../../assets/IconsVendors/unitedconcordia.svg';
import financialfinesse from '../../../assets/IconsVendors/financialfinesse.svg';
import virta from '../../../assets/IconsVendors/virta.svg';
import vs from '../../../assets/IconsVendors/vs.svg';
import vsp from '../../../assets/IconsVendors/vsp.svg';
import wtw from '../../../assets/IconsVendors/wtw.svg';
import davisVision from '../../../assets/IconsVendors/davis vision.svg';
import { saveBreadCrumbData } from '../../../actions/breadCrumbActions';
const currentEnvironment = window.currentEnvironment;

const Index = (props) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [vendorName, setVendorName] = useState(null);
    const [layoutListData, setLayoutListData] = useState(null);
    const [rows, setRows] = useState(null);
    const [cols, setCols] = useState(null);
    const [rowsData, setRowsData] = useState([]);
    const [isVendorOpen, setIsVendorOpen] = useState(true);
    const [isProgramOpen, setIsProgramOpen] = useState(true);
    const [isContractOpen, setIsContractOpen] = useState(true);
    const [productionRptStatus, setProductionRptStatus] = useState(null);
    const [columns, setColumns] = useState([]);
    const [layoutData, setLayoutData] = useState([]);
    const [vendorIndex, setVendorIndex] = useState(0);
    const [programManagerIndex, setProgramManagerIndex] = useState(0);
    const [contractManagerIndex, setContractManagerIndex] = useState(0);
    const [vendorOverviewLoading, setVendorOverviewLoading] = useState(false);
    const [layoutLoading, setLayoutLoading] = useState(false);
    const [dfProdLoading, setDFProdLoading] = useState(false);
    const isFirst = false;

    const getVendorOverviewAPI = (vendor) => {
        // console.log("vendor: ", vendor)
        let body = { src: vendor, typ: 'Overview', layout: '' };
        // setLoading(true);
        setVendorOverviewLoading(true);
        getVendorOverview(body)
            .then((response) => {
                // setLoading(false);
                if (response && response.data) {
                    setData(response.data);
                    setVendorOverviewLoading(false);
                }
                // console.log("60---------",response.data)
            })
            .catch((error) => {
                // setLoading(false);
                setVendorOverviewLoading(false);
            });
    };

    const getDFProductionReportStatusAPI = () => {
        // setLoading(true);
        async function fetchData() {
            setDFProdLoading(true);
            const response = await getDFProductionReportStatus();
            if (response && response.data) {
                setProductionRptStatus(response.data);

                if (response.data && response.data.header?.length > 0) {
                    let temp = [];
                    for (const elm in response.data.header) {
                        setLayoutData(response.data.header[elm]);
                    }
                }
                setDFProdLoading(false);
            }
            // setLoading(false);
        }
        fetchData();
    };

    const getLayoutDetailsAPI = (vendor) => {
        // console.log("getLayoutDetailsAPI: ", vendor)
        let body1 = { src: vendor, typ: 'Vendor Master', layout: '' };
        getLayoutDetails(body1)
            .then((response) => {
                // console.log('getLayoutDetailsAPI: ', response.data);
                // setLoading(false);
                setLayoutLoading(true);
                if (response && response.data) {
                    setLayoutListData(response.data);
                    let tempArrList = [];
                    response.data.data.map((item, i) => {
                        let listObj = {};
                        item.forEach((ele, index) => {
                            listObj['col_' + index] = ele;
                        });
                        tempArrList.push(listObj);
                    });
                    setRowsData(tempArrList);
                    setLayoutLoading(false);
                }
            })
            .catch((error) => {
                // setLoading(false);
                setLayoutLoading(false);
            });
    };

    useEffect(() => {
        if (props?.breadcrumb?.length > 3) {
            let vName = props.breadcrumb[3];
            setVendorName(vName);
        }
    }, [props?.breadcrumb]);

    useEffect(() => {
        if (vendorName) {
            getVendorOverviewAPI(vendorName);
            getLayoutDetailsAPI(vendorName);
            getDFProductionReportStatusAPI();
        }
    }, [vendorName]);

    const onColumnClick = (index) => {
        let id = 'col_' + index;
        let dom = document.getElementById(id);
        if (dom && dom.src) {
            if (dom.src.includes('/sort.')) {
                dom.src = SortDescImg;
                onSortCall({ column: 'col_' + index, direction: 'asc' });
            } else {
                dom.src = SortImg;
                onSortCall({ column: 'col_' + index, direction: 'desc' });
            }
        }
    };

    const onSortCall = (value) => {
        let tempRows = [];
        layoutListData?.data.map((item) => {
            let listObj = {};
            item.forEach((ele, index) => {
                listObj['col_' + index] = ele;
            });
            tempRows.push(listObj);
        });

        let arr = [];
        if (value?.column) {
            if (value?.direction == 'asc') {
                arr = sortObjectsArray(tempRows, value.column);
            } else {
                arr = sortObjectsArray(tempRows, value.column, 'desc');
            }
            setRowsData(arr);
        }
    };

    const setLayoutTableColumn = () => {
        let tempCols = [];
        layoutListData?.description?.forEach((elm, index) => {
            let str = elm.name;
            str = str.charAt(0).toUpperCase() + str.slice(1);
            str = str.split('_').join(' ');
            let colObj = {
                label: (
                    <OverlayTrigger
                        trigger="hover"
                        placement="top-start"
                        overlay={
                            <Popover className="vendor-contact-popover" style={{ opacity: '1', maxWidth: 350 }}>
                                <div className="df-home-tooltip">{elm.desc}</div>
                            </Popover>
                        }
                        rootClose
                    >
                        <div className="df-home-dt-col-head" style={{ minWidth: '180px' }}>
                            {str}
                            <img
                                onClick={() => {
                                    onColumnClick(index);
                                }}
                                id={'col_' + index}
                                src={SortImg}
                                style={{ marginLeft: 8, cursor: 'pointer' }}
                            />
                        </div>
                    </OverlayTrigger>
                ),
                field: 'col_' + index
            };
            tempCols.push(colObj);
        });
        setCols(tempCols);
    };

    const setLayoutTableRow = () => {
        let tempRows = [];
        if (rowsData) {
            rowsData.map((item) => {
                let rowObj = {};
                layoutListData?.description.map((ele, index) => {
                    rowObj['col_' + index] =
                        index == 0 ? (
                            <Link
                                className="df-link"
                                to={{
                                    pathname: `/data-factory/data-sources/${vendorName}/${item['col_' + index]}`,
                                    state: { layout: item['col_' + index] }
                                }}
                            >
                                {item['col_' + index]}
                            </Link>
                        ) : (
                            item['col_' + index]
                        );
                });
                tempRows.push(rowObj);
            });
            setRows(tempRows);
        }
    };

    const renderMoreContactInfo = (data) => {
        return data.Name.map((det, index) => {
            return (
                <>
                    {index == 0 ? null : (
                        <div className="d-flex">
                            <div className="d-flex">
                                <IC_Name />
                                <div className="vendor-contact-txt" style={{ marginRight: 20 }}>
                                    {det}
                                </div>
                            </div>
                            {data?.Email?.length > index ? (
                                <div className="d-flex">
                                    <div>
                                        <Email />
                                    </div>
                                    <div className="vendor-contact-txt" style={{ marginRight: 20 }}>
                                        <a href={'mailto:' + data.Email[index]}>{data.Email[index]}</a>
                                    </div>
                                </div>
                            ) : null}
                            {data?.Phone_number?.length > index ? (
                                <div className="d-flex">
                                    <IC_PhoneNumber />
                                    <div className="vendor-contact-txt" style={{ marginRight: 20 }}>
                                        {data.Phone_number[index]}
                                    </div>
                                </div>
                            ) : data?.Phone_Number?.length > index ? (
                                <div className="d-flex">
                                    <IC_PhoneNumber />
                                    <div className="vendor-contact-txt" style={{ marginRight: 20 }}>
                                        {data.Phone_Number[index]}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    )}
                </>
            );
        });
    };

    const renderContactsDetails = () => {
        if (data) {
            return (
                <div style={{ backgroundColor: '#EDEEF1', height: '770px' }}>
                    {data?.Vendor_Contact_Details ? (
                        data.Vendor_Contact_Details?.Name?.length > 0 ? (
                            <div className="vendor-contact-div">
                                <div className="sub-header-custom"> Vendor Contacts</div>
                                <div className="details">
                                    <div className="accordion-title" onClick={() => setIsVendorOpen(!isVendorOpen)}>
                                        <div>
                                            <div className="sub-header-custom">
                                                {data.Vendor_Contact_Details.Name[0]}
                                            </div>
                                        </div>
                                        <div>
                                            <img className="accordian-arrow" src={isVendorOpen ? Up : Down} />
                                        </div>
                                    </div>
                                    {isVendorOpen && (
                                        <div className="accordion-content">
                                            {data.Vendor_Contact_Details?.Email?.length > 0 ? (
                                                <div className="accordion-sub-content">
                                                    <div>
                                                        <img src={Email} />
                                                    </div>
                                                    <div className="vendor-contact-txt">
                                                        <a href={'mailto:' + data.Vendor_Contact_Details.Email[0]}>
                                                            {data.Vendor_Contact_Details.Email[0]}
                                                        </a>
                                                        {/* <div>{data.Vendor_Contact_Details.Email[0]}</div> */}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {data.Vendor_Contact_Details?.Phone_number?.length > 0 ? (
                                                <div className="accordion-sub-content">
                                                    <img src={Phone} />
                                                    <div className="vendor-contact-txt">
                                                        {data.Vendor_Contact_Details.Phone_number[0]}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {data.Vendor_Contact_Details?.Phone_Number?.length > 0 ? (
                                                <div className="accordion-sub-content">
                                                    <img src={Phone} />
                                                    <div className="vendor-contact-txt">
                                                        {data.Vendor_Contact_Details.Phone_Number[0]}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                    {data.Vendor_Contact_Details?.Name?.length > 1 ? (
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="bottom"
                                            overlay={
                                                <Popover
                                                    className="vendor-contact-popover"
                                                    style={{ maxWidth: 'fit-content' }}
                                                >
                                                    {renderMoreContactInfo(data.Vendor_Contact_Details)}
                                                </Popover>
                                            }
                                            rootClose
                                        >
                                            <div
                                                className="vendor-contact-txt"
                                                style={{
                                                    color: '#3b8bc6',
                                                    marginLeft: '-20px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {'+' + (data.Vendor_Contact_Details.Name.length - 1) + ' More'}
                                            </div>
                                        </OverlayTrigger>
                                    ) : null}
                                </div>
                            </div>
                        ) : null
                    ) : null}

                    {data?.Program_Manager ? (
                        data?.Program_Manager?.Name?.length > 0 ? (
                            <div className="vendor-contact-div">
                                <div className="sub-header-custom"> Program Manager</div>
                                <div className="details">
                                    <div className="accordion-title" onClick={() => setIsProgramOpen(!isProgramOpen)}>
                                        <div>
                                            <div className="sub-header-custom">{data.Program_Manager.Name[0]}</div>
                                        </div>
                                        <div>
                                            <img className="accordian-arrow" src={isProgramOpen ? Up : Down} />
                                        </div>
                                    </div>
                                    {isProgramOpen && (
                                        <div className="accordion-content">
                                            {data.Program_Manager?.Email?.length > 0 ? (
                                                <div className="accordion-sub-content">
                                                    <div>
                                                        <img src={Email} />
                                                    </div>
                                                    <div className="vendor-contact-txt">
                                                        {/* <div>{data.Program_Manager.Email[0]}</div> */}
                                                        <a href={'mailto:' + data.Program_Manager.Email[0]}>
                                                            {data.Program_Manager.Email[0]}
                                                        </a>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {data.Program_Manager?.Phone_Number?.length > 0 ? (
                                                <div className="accordion-sub-content">
                                                    <img src={Phone} />
                                                    <div className="vendor-contact-txt">
                                                        {data.Program_Manager.Phone_Number[0]}
                                                    </div>
                                                </div>
                                            ) : null}
                                            {data.Program_Manager?.Name?.length > 1 ? (
                                                <OverlayTrigger
                                                    trigger="click"
                                                    placement="bottom"
                                                    overlay={
                                                        <Popover
                                                            className="vendor-contact-popover"
                                                            style={{ maxWidth: 'fit-content' }}
                                                        >
                                                            {renderMoreContactInfo(data.Program_Manager)}
                                                        </Popover>
                                                    }
                                                    rootClose
                                                >
                                                    <div
                                                        className="vendor-contact-txt"
                                                        style={{
                                                            color: '#3b8bc6',
                                                            marginLeft: '-20px',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        {'+' + (data.Program_Manager.Name.length - 1) + ' More'}
                                                    </div>
                                                </OverlayTrigger>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : null
                    ) : null}

                    {data?.Contact_Manager ? (
                        data.Contact_Manager?.Name?.length > 0 ? (
                            <div className="vendor-contact-div">
                                <div className="sub-header-custom"> Contact Manager</div>
                                <div className="details">
                                    <div className="accordion-title" onClick={() => setIsContractOpen(!isContractOpen)}>
                                        <div>
                                            <div className="sub-header-custom">{data.Contact_Manager.Name[0]}</div>
                                        </div>
                                        <div>
                                            <img className="accordian-arrow" src={isContractOpen ? Up : Down} />
                                        </div>
                                    </div>
                                    {isContractOpen && (
                                        <div className="accordion-content">
                                            {data.Contact_Manager?.Email?.length > 0 ? (
                                                <div className="accordion-sub-content">
                                                    <div>
                                                        <img src={Email} />
                                                    </div>
                                                    <div className="vendor-contact-txt">
                                                        <a href={'mailto:' + data.Contact_Manager.Email[0]}>
                                                            {' '}
                                                            {data.Contact_Manager.Email[0]}
                                                        </a>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {data.Contact_Manager?.Phone_Number?.length > 0 ? (
                                                <div className="accordion-sub-content">
                                                    <img src={Phone} />
                                                    <div className="vendor-contact-txt">
                                                        {data.Contact_Manager.Phone_Number[0]}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                    {data.Contact_Manager?.Name?.length > 1 ? (
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="bottom"
                                            overlay={
                                                <Popover
                                                    className="vendor-contact-popover"
                                                    style={{ maxWidth: 'fit-content' }}
                                                >
                                                    {renderMoreContactInfo(data.Contact_Manager)}
                                                </Popover>
                                            }
                                            rootClose
                                        >
                                            <div
                                                className="vendor-contact-txt"
                                                style={{
                                                    color: '#3b8bc6',
                                                    marginLeft: '-20px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {'+' + (data.Contact_Manager.Name.length - 1) + ' More'}
                                            </div>
                                        </OverlayTrigger>
                                    ) : null}
                                </div>
                            </div>
                        ) : null
                    ) : null}
                </div>
            );
        }
    };

    const vendorIcon = (icon) => {
        switch (icon?.toLowerCase()) {
            case 'accolade':
                return accolade;
            case 'cigna':
                return cigna;
            case 'compsych':
                return compsych;
            case 'crossover':
                return crossover;
            case 'cvs':
                return cvs;
            case 'fidelity':
                return fidelity;
            case 'grand rounds':
                return grandrounds;
            case 'health equity':
                return healthquity;
            case 'ibc':
                return independence;
            case 'kaiser':
                return kaiser;
            case 'quest diagnostics':
                return quest;
            case 'sedgwick':
                return sedwick;
            case 'tufts':
                return tufts;
            case 'ucci':
                return unitedconcordia;
            case 'virta':
                return virta;
            case 'vs':
                return vs;
            case 'vsp':
                return vsp;
            case 'wtw':
                return wtw;
            case 'financial finesse':
                return financialfinesse;
            case 'davis':
                return davisVision;
            case 'included health':
                return includedhealth;
            default:
                return ['DEV', 'UAT', 'PROD', 'PRODLOCAL', 'DEVLOCAL', 'PRODNEW'].includes(currentEnvironment)
                    ? ClientLogo
                    : DevClientLogo;
        }
    };

    return (
        <>
            <div className="vendor-overview-container">
                {vendorOverviewLoading || layoutLoading || dfProdLoading ? (
                    <div className="loader-div">
                        <ProgressSpinner />
                    </div>
                ) : (
                    <div className="main-container">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                overflowY: 'scroll'
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className="vendor-logo">
                                    {/* {console.log(data.Icons)} */}
                                    <img className="company-sub-logo" src={vendorIcon(vendorName)} alt="XYZ_Company" />
                                </div>
                                <div
                                    style={{
                                        // flex: 3,
                                        paddingTop: '20px',
                                        paddingBottom: '20px',
                                        paddingLeft: '10px'
                                    }}
                                >
                                    {data?.page_header && data?.page_header?.trim() != '' ? (
                                        <div className="about-header">{`About ${data.page_header}`}</div>
                                    ) : null}
                                    {data?.Description && data?.Description?.trim() != '' ? (
                                        <div
                                            className="about-desc"
                                        // style={{ paddingBottom: 20, borderBottom: '1px solid #e4e4f1' }}
                                        >
                                            {data.Description}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* <div className="first-container"></div> */}
                            <div className="second-container" style={{ minHeight: '85vh' }}>
                                {data && (
                                    <div className="contact-details">
                                        <div className="vendor-contact-div">
                                            {data?.Vendor_Contact_Details && (
                                                <div className="sub-header-custom">Vendor Contacts</div>
                                            )}
                                            {data?.Vendor_Contact_Details?.map((item, index) => (
                                                <ContactDetailsCard
                                                    name={item.Name}
                                                    email={item.Email}
                                                    phone={item?.Phone_Number}
                                                    isSelected={index === vendorIndex}
                                                    setSelected={() => setVendorIndex(index)}
                                                />
                                            ))}
                                        </div>
                                        <div className="vendor-contact-div">
                                            {data?.Program_Manager && (
                                                <div className="sub-header-custom">Program Manager</div>
                                            )}
                                            {data?.Program_Manager?.map((item, index) => (
                                                <ContactDetailsCard
                                                    name={item.Name}
                                                    email={item.Email}
                                                    phone={item?.Phone_Number}
                                                    isSelected={index === programManagerIndex}
                                                    setSelected={() => setProgramManagerIndex(index)}
                                                />
                                            ))}
                                        </div>
                                        <div className="vendor-contact-div">
                                            {data?.Contact_Manager && (
                                                <div className="sub-header-custom">Contact Manager</div>
                                            )}
                                            {data?.Contact_Manager?.map((item, index) => (
                                                <ContactDetailsCard
                                                    name={item.Name}
                                                    email={item.Email}
                                                    phone={item?.Phone_Number}
                                                    isSelected={index === contractManagerIndex}
                                                    setSelected={() => setContractManagerIndex(index)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <div className="second-right-container ">
                                    {
                                        <ModuleCard
                                            vendorName={vendorName}
                                            location={props.location.pathname}
                                            breadcrumb={props.breadcrumb}
                                            saveBreadCrumb={props.saveBreadCrumbData}
                                        />
                                    }

                                    {vendorName ? (
                                        <>
                                            <Limitation isFrom="VendorHome" vendor={vendorName} />
                                            <Qnas isFrom="VendorHome" vendor={vendorName} />
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    breadcrumb: state.breadcrumb.breadCrumbData
});
// const mapDispatchToProps = (state) => ({});
export default connect(mapStateToProps, { saveBreadCrumbData })(Index);
