import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AccessManagementIcon, HighCostClaimantsIcon, Logout, ProfileAvatar } from '../assets/icons';
import { getInitials, goclone, urlSlug, PAGE_SIZE, removeHyphen, titleCase } from '../utils/common-helper';
import appLogo from './../assets/images/new_logo.jpg';
import doubleArrow from './../assets/images/double_arrow.svg';
import graphic from './../assets/images/OBJECTS.svg';
import searchIcon from './../assets/images/search.svg';
import DevClientLogo from './../assets/images/xyz_company.png';
import ClientLogo from './../assets/images/client-logo.jpg';
import {
    Plus,
    Minus,
    Home,
    SpendHand,
    Assessment,
    ProductEva,
    UsageReport,
    StandardReport,
    DownArrowNew,
    UpArrowNew,
    UpArrowSelected,
    Myfavorites,
    SSAIcon
} from '../assets/icons';
import InsightStudio from '../assets/applogo/insight_studio_icon.svg';
import StandardReports from '../assets/applogo/StandardReports.svg';
import ProductEvaluation from '../assets/applogo/product_evaluation_active.svg';
import MyFavourites from '../assets/applogo/my_favorites_active.svg';
import UsageReports from '../assets/applogo/usage_report_active.svg';
import HighCostClaimants from '../assets/applogo/high_cost_claimaints.svg';
import WorkInProcess from '../assets/applogo/wip_active.svg';
import DataFactory from '../assets/applogo/data_factory_active.svg';
import AccessManagement from '../assets/applogo/accessmanagement.svg';
import userProfile from '../assets/applogo/userprofile.svg';
import PhysicalWellbeing from '../assets/applogo/physical_wellbeing.svg';
import MentalWellbeing from '../assets/applogo/mentalwellbeing.svg';
import RiskScore from '../assets/applogo/risk_score.svg';
import { searchLink } from '../utils/api-helper';
import { Paginator } from 'primereact/paginator';
import { saveBreadCrumbData } from '../actions/breadCrumbActions';
import { useHistory } from 'react-router-dom';
import { encryptDecryptString } from '../utils/ms-helper';
import './style.scss';
const currentEnvironment = window.currentEnvironment;

function AppHeader(props) {
    let access = JSON.parse(encryptDecryptString(sessionStorage.getItem('COGNITIO_ROLE')));
    const [selectedCategory, setSelectedCategory] = useState({
        name: 'All Category',
        index: 0
    });
    const [displaySearchPopup, setDisplaySearchPopup] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchingData, setSearchingData] = useState([]);
    const [isResult, setIsResult] = useState(false);
    const [foundResult, setFoundResult] = useState(null);
    const [noDataFound, setNoDataFound] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRecordsCount, setTotalRecordsCount] = useState(0);
    const [basicFirst, setBasicFirst] = useState(0);
    const [basicRows, setBasicRows] = useState(PAGE_SIZE);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    //Get module name from the path
    const APP_HEADER = useMemo(() => {
        const moduleName = titleCase(removeHyphen(history.location.pathname.split('/')[1]));
        return moduleName === 'High Cost Claimants' ? 'High-Cost Claimants' : moduleName;
    }, [history.location.pathname]);

    const onCategoryChange = (e) => {
        setSelectedCategory(e.value);
    };

    const getpath = (setIcon) => {
        let path = history?.location?.pathname;
        if (path.includes('/data-factory')) {
            return DataFactory;
        } else if (path.includes('/my-profile')) {
            return AccessManagement;
        } else if (path.includes('/access-management')) {
            return userProfile;
        } else if (path.includes('/standard-reports')) {
            return StandardReports;
        } else if (path.includes('/product-evaluation')) {
            return ProductEvaluation;
        } else if (path.includes('/my-favorites')) {
            return MyFavourites;
        } else if (path.includes('/high-cost-claimants')) {
            return HighCostClaimants;
        } else if (path.includes('/work-in-process')) {
            return WorkInProcess;
        } else if (path.includes('/usage-report')) {
            return UsageReports;
        } else if (path.includes('/insights-studio')) {
            return InsightStudio;
        } else if (path.includes('/physical-wellbeing')) {
            return PhysicalWellbeing;
        } else if (path.includes('/emotional-wellbeing')) {
            return MentalWellbeing;
        } else if (path.includes('/risk-score')) {
            return RiskScore;
        } else {
            return InsightStudio;
        }
    };

    const onSearch = (e) => {
        e.preventDefault();
        if (searchText !== '') {
            searchAPI(pageNumber);
        } else {
            setFoundResult([]);
            setIsResult(false);
            setSearchText('');
            setNoDataFound(true);
        }
    };

    useEffect(() => {
        let options = [];
        options.push({ name: 'All Category', index: 0 });
        if (access?.length) {
            access.map((mainOption, index) => {
                let obj = {
                    name: '',
                    index: index
                };
                obj.name = mainOption.module;
                options.push(obj);
            });
            setCategoryOptions(options);
            setSearchingData(access);
        }
    }, []);

    const searchAPI = (pgNum) => {
        let request = {
            role: props?.userData?.user?.userRole,
            module: selectedCategory?.name == 'All Category' ? '' : selectedCategory?.name,
            pg: pgNum,
            pgsz: PAGE_SIZE,
            searchtext: searchText
        };
        setLoading(true);
        searchLink(request)
            .then((response) => {
                if (response && response?.data && response?.data?.length) {
                    let totalRecords = response.data[0]?.totalCount;
                    let arr = response.data[0].data?.filter((dt, index) => {
                        let _dt = JSON.stringify(dt);
                        return (
                            index ===
                            response.data[0].data.findIndex((ob) => {
                                return JSON.stringify(ob) == _dt;
                            })
                        );
                    });

                    let finalArray = [];
                    arr?.map((res, index) => {
                        if (
                            res[0] == null &&
                            res[1] == null &&
                            res[2] == null &&
                            res[3] == null &&
                            res[4] == null &&
                            res[5] == null &&
                            res[6] == null
                        ) {
                            totalRecords = totalRecords - 1;
                        } else {
                            let dArr = [];
                            let obj = {};
                            obj['title'] = res[6];
                            if (
                                finalArray?.some(
                                    (r) =>
                                        r?.data[0][6]?.toLowerCase() == res[6]?.toLowerCase() &&
                                        r?.data[0][3]?.toLowerCase() == res[3]?.toLowerCase()
                                )
                            ) {
                                let arIndex = finalArray?.findIndex(
                                    (x) =>
                                        x?.data[0][6]?.toLowerCase() == res[6]?.toLowerCase() &&
                                        x?.data[0][3]?.toLowerCase() == res[3]?.toLowerCase()
                                );
                                if (arIndex != -1) {
                                    dArr = finalArray[arIndex]['data'];
                                    dArr.push(res);
                                    finalArray[arIndex]['data'] = dArr;
                                }
                            } else {
                                dArr.push(res);
                                finalArray.push({ Module: res[6], type: res[3], data: dArr });
                            }
                        }
                    });
                    setTotalRecordsCount(totalRecords);
                    setFoundResult(finalArray);
                    if (finalArray?.length) {
                        setIsResult(true);
                        setNoDataFound(false);
                    } else {
                        setNoDataFound(true);
                        setIsResult(false);
                    }
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);

                setNoDataFound(true);
                setIsResult(false);
            });
    };

    const resetSearch = () => {
        setSelectedCategory({ name: 'All Category', index: 0 });
        setSearchText('');
        setFoundResult([]);
        setIsResult(false);
        setNoDataFound(false);
    };

    const signOut = () => {
        props.LogoutFunction();
    };

    const pageChange = (e) => {
        setBasicFirst(e.first);
        setBasicRows(e.rows);
        setPageNumber(e.page + 1);
        searchAPI(e.page + 1);
    };

    const renderSearchResult = (result, index) => {
        if (result?.type?.toLowerCase() == 'overview') {
        } else if (result?.type?.toLowerCase() == 'vendor master') {
        } else if (
            result?.type?.toLowerCase() == 'qna for vendor' ||
            result?.type?.toLowerCase() == 'limitations for vendor'
        ) {
            return result?.data?.map((res, ind) => {
                return (
                    <div className="d-flex">
                        <div className="result-options">{res[6]}</div>
                        <img src={doubleArrow} alt="dopuble arroww" />
                        {history?.location?.pathname == `/${urlSlug(res[6])}/data-sources/${res[5]}` &&
                            history?.location?.state == { vendor: res[5] } ? (
                            <Link
                                className="option-text"
                                onClick={() => {
                                    props.saveBreadCrumbData(['Home', res[6], 'Data Sources', res[5]]);
                                    resetSearch();
                                    setDisplaySearchPopup(false);
                                }}
                                style={{
                                    padding: '20px 25px',
                                    fontFamily: 'Montserrat-Medium',
                                    color: '#3b8bc6'
                                }}
                            >
                                {res[5]}
                            </Link>
                        ) : (
                            <Link
                                to={{
                                    pathname: `${urlSlug(res[6])}/data-sources/${res[5]}`,
                                    state: { vendor: res?.datasource }
                                }}
                                className="option-text"
                                onClick={() => {
                                    props.saveBreadCrumbData(['Home', res[6], 'Data Sources', res[5]]);
                                    resetSearch();
                                    setDisplaySearchPopup(false);
                                }}
                                style={{
                                    padding: '20px 25px',
                                    fontFamily: 'Montserrat-Medium',
                                    color: '#3b8bc6'
                                }}
                            >
                                {res[5]}
                            </Link>
                        )}
                    </div>
                );
            });
        } else if (
            result?.type?.toLowerCase() == 'standard report overview' ||
            result?.type?.toLowerCase() == 'key points' ||
            result?.type?.toLowerCase() == 'program evaluation overview'
        ) {
            if (result?.type?.toLowerCase() != 'key points') {
                return result?.data?.map((res, ind) => {
                    if (res[6] != null && res[2] != null && (res[1] != null || res[0])) {
                        return (
                            <div className="d-flex">
                                <div className="result-options">{res[2]}</div>
                                <img src={doubleArrow} alt="dopuble arroww" />
                                {history?.location?.pathname == `/${urlSlug(res[6])}/${res[2]}/${res[0]}` ||
                                    history?.location?.pathname == `/${urlSlug(res[6])}/${res[2]}/${res[1]}` ? (
                                    <Link
                                        // to={`${urlSlug(res?.Module)}/${res?.sub_type}`}
                                        className="option-text"
                                        onClick={() => {
                                            props.saveBreadCrumbData(['Home', res[6], res[2]]);
                                            resetSearch();
                                            setDisplaySearchPopup(false);
                                        }}
                                        style={{
                                            padding: '20px 25px',
                                            fontFamily: 'Montserrat-Medium',
                                            color: '#3b8bc6'
                                        }}
                                    >
                                        {result?.type?.toLowerCase() == 'program evaluation overview' ? res[0] : res[1]}
                                    </Link>
                                ) : (
                                    <Link
                                        to={
                                            result?.type?.toLowerCase() == 'program evaluation overview'
                                                ? `/${urlSlug(res[6])}/${res[2]}/${res[0]}`
                                                : `/${urlSlug(res[6])}/${res[2]}/${res[1]}`
                                        }
                                        className="option-text"
                                        onClick={() => {
                                            props.saveBreadCrumbData([
                                                'Home',
                                                res[6],
                                                res[2],
                                                result?.type?.toLowerCase() == 'program evaluation overview'
                                                    ? res[0]
                                                    : res[1]
                                            ]);
                                            resetSearch();
                                            setDisplaySearchPopup(false);
                                        }}
                                        style={{
                                            padding: '20px 25px',
                                            fontFamily: 'Montserrat-Medium',
                                            color: '#3b8bc6'
                                        }}
                                    >
                                        {result?.type?.toLowerCase() == 'program evaluation overview' ? res[0] : res[1]}
                                    </Link>
                                )}
                            </div>
                        );
                    } else {
                        return null;
                    }
                });
            }
        } else if (result?.type?.toLowerCase() == 'faqs') {
        } else {
            return result?.data?.map((res, ind) => {
                if (res[5] != null && res[4] != null && res[2] != null && res[3] != null) {
                    return (
                        <div className="d-flex">
                            <div className="result-options">{res[5]}</div>
                            <img src={doubleArrow} alt="dopuble arroww" />
                            {history?.location?.pathname == `/${urlSlug(res[6])}/data-sources/${res[5]}/${res[4]}` && {
                                vendor: res[5],
                                fileCategory: res[4],
                                sub_type: res[2],
                                type: res[3]
                            } ? (
                                <Link
                                    // to={{
                                    //   pathname: `${urlSlug(res?.Module)}/data-sources/${res?.datasource}/${res?.layout}`,
                                    //   state: { vendor: res?.datasource, fileCategory: res?.layout, sub_type: res?.sub_type, type: res?.type }
                                    // }}
                                    className="option-text"
                                    onClick={() => {
                                        props.saveBreadCrumbData(['Home', res[6], 'Data Sources', res[5], res[4]]);
                                        resetSearch();
                                        setDisplaySearchPopup(false);
                                    }}
                                    style={{
                                        padding: '20px 25px',
                                        fontFamily: 'Montserrat-Medium',
                                        color: '#3b8bc6'
                                    }}
                                >
                                    {res[4]}
                                </Link>
                            ) : (
                                <Link
                                    to={{
                                        pathname: `${urlSlug(res[6])}/data-sources/${res[5]}/${res[4]}`,
                                        state: {
                                            vendor: res[5],
                                            fileCategory: res[4],
                                            sub_type: res[2],
                                            type: res[3]
                                        }
                                    }}
                                    className="option-text"
                                    onClick={() => {
                                        props.saveBreadCrumbData(['Home', res[6], 'Data Sources', res[5], res[4]]);
                                        resetSearch();
                                        setDisplaySearchPopup(false);
                                    }}
                                    style={{
                                        padding: '20px 25px',
                                        fontFamily: 'Montserrat-Medium',
                                        color: '#3b8bc6'
                                    }}
                                >
                                    {res[4]}
                                </Link>
                            )}
                        </div>
                    );
                } else if (res[5] && res[4]) {
                    return (
                        <div className="d-flex">
                            <div className="result-options">{res[5]}</div>
                            <img src={doubleArrow} alt="dopuble arroww" />
                            {history?.location?.pathname == `/${urlSlug(res[6])}/data-sources/${res[5]}/${res[4]}` && {
                                vendor: res[5],
                                fileCategory: res[4],
                                type: res[3] ? res[3] : null,
                                sub_type: res[2] ? res[2] : null
                            } ? (
                                <Link
                                    className="option-text"
                                    onClick={() => {
                                        props.saveBreadCrumbData(['Home', res[6], 'Data Sources', res[5], res[4]]);
                                        resetSearch();
                                        setDisplaySearchPopup(false);
                                    }}
                                    style={{
                                        padding: '20px 25px',
                                        fontFamily: 'Montserrat-Medium',
                                        color: '#3b8bc6'
                                    }}
                                >
                                    {res[4]}
                                </Link>
                            ) : (
                                <Link
                                    to={{
                                        pathname: `${urlSlug(res[6])}/data-sources/${res[5]}/${res[4]}`,
                                        state: {
                                            vendor: res[5],
                                            fileCategory: res[4],
                                            type: res[3] ? res[3] : null,
                                            sub_type: res[2] ? res[2] : null
                                        }
                                    }}
                                    className="option-text"
                                    onClick={() => {
                                        // history.push({ pathname: `${urlSlug(res[6])}/data-sources/${res[5]}/${res[4]}`, state: { vendor: res[5], fileCategory: res[4], type: res[3] ? res[3] : null, sub_type: res[2] ? res[2] : null } })
                                        props.saveBreadCrumbData(['Home', res[6], 'Data Sources', res[5], res[4]]);
                                        resetSearch();
                                        setDisplaySearchPopup(false);
                                    }}
                                    style={{
                                        padding: '20px 25px',
                                        fontFamily: 'Montserrat-Medium',
                                        color: '#3b8bc6'
                                    }}
                                >
                                    {res[4]}
                                </Link>
                            )}
                        </div>
                    );
                } else if (res[6] && res[5]) {
                    return (
                        <div className="d-flex">
                            <div className="result-options">{res[6]}</div>
                            <img src={doubleArrow} alt="dopuble arroww" />
                            {history?.location?.pathname == `/${urlSlug(res[6])}/data-sources/${res[5]}` &&
                                history?.location?.state == { vendor: res?.datasource } ? (
                                <Link
                                    className="option-text"
                                    onClick={() => {
                                        props.saveBreadCrumbData(['Home', res[6], 'Data Sources', res[5]]);
                                        resetSearch();
                                        setDisplaySearchPopup(false);
                                    }}
                                    style={{
                                        padding: '20px 25px',
                                        fontFamily: 'Montserrat-Medium',
                                        color: '#3b8bc6'
                                    }}
                                >
                                    {res[5]}
                                </Link>
                            ) : (
                                <Link
                                    to={{
                                        pathname: `/${urlSlug(res[6])}/data-sources/${res[5]}`,
                                        state: { vendor: res?.datasource }
                                    }}
                                    className="option-text"
                                    onClick={() => {
                                        props.saveBreadCrumbData(['Home', res[6], 'Data Sources', res[5]]);
                                        resetSearch();
                                        setDisplaySearchPopup(false);
                                    }}
                                    style={{
                                        padding: '20px 25px',
                                        fontFamily: 'Montserrat-Medium',
                                        color: '#3b8bc6'
                                    }}
                                >
                                    {res[5]}
                                </Link>
                            )}
                        </div>
                    );
                } else if (res[6] && res[3]) {
                    return (
                        <div className="d-flex">
                            <div className="result-options">{res[6]}</div>
                            <img src={doubleArrow} alt="dopuble arroww" />
                            {history?.location?.pathname == `/${urlSlug(res[6])}/data-sources/${res[3]}` &&
                                history?.location?.state == { vendor: res?.datasource } ? (
                                <Link
                                    className="option-text"
                                    onClick={() => {
                                        props.saveBreadCrumbData(['Home', res[6], 'Data Sources', res[3]]);
                                        resetSearch();
                                        setDisplaySearchPopup(false);
                                    }}
                                    style={{
                                        padding: '20px 25px',
                                        fontFamily: 'Montserrat-Medium',
                                        color: '#3b8bc6'
                                    }}
                                >
                                    {res[3]}
                                </Link>
                            ) : (
                                <Link
                                    to={{ pathname: `/${urlSlug(res[6])}/data-sources/${res[3]}` }}
                                    className="option-text"
                                    onClick={() => {
                                        props.saveBreadCrumbData(['Home', res[6], 'Data Sources', res[3]]);
                                        resetSearch();
                                        setDisplaySearchPopup(false);
                                    }}
                                    style={{
                                        padding: '20px 25px',
                                        fontFamily: 'Montserrat-Medium',
                                        color: '#3b8bc6'
                                    }}
                                >
                                    {res[3]}
                                </Link>
                            )}
                        </div>
                    );
                } else {
                    return (
                        <div style={{ padding: '20px 25px' }}>
                            {history?.location?.pathname == `/${urlSlug(res[6])}` ? (
                                <Link
                                    // to={{ pathname: `${urlSlug(res?.Module)}` }}
                                    className="option-text"
                                    onClick={() => {
                                        props.saveBreadCrumbData(['Home', res[6]]);
                                        resetSearch();
                                        setDisplaySearchPopup(false);
                                    }}
                                    style={{ fontFamily: 'Montserrat-Medium', color: '#3b8bc6' }}
                                >
                                    {res[6]}
                                </Link>
                            ) : (
                                <Link
                                    to={{ pathname: `${urlSlug(res[6])}` }}
                                    className="option-text"
                                    onClick={() => {
                                        props.saveBreadCrumbData(['Home', res[6]]);
                                        resetSearch();
                                        setDisplaySearchPopup(false);
                                    }}
                                    style={{ fontFamily: 'Montserrat-Medium', color: '#3b8bc6' }}
                                >
                                    {res[6]}
                                </Link>
                            )}
                        </div>
                    );
                }
            });
        }
    };

    const renderSearchPopupContent = () => {
        return (
            <>
                <form onSubmit={(e) => onSearch(e)}>
                    <div className="searchbar-div" id="search-dropdown">
                        <Dropdown
                            style={{ width: '200px', height: '38px' }}
                            value={selectedCategory}
                            options={categoryOptions}
                            onChange={onCategoryChange}
                            optionLabel="name"
                            placeholder="Add Category"
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }} className="p-inputgroup">
                            <InputText
                                style={{ height: '38px' }}
                                placeholder="Search..."
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Button
                                type="submit"
                                style={{
                                    height: '38px',
                                    width: '54px',
                                    borderColor: 'none',
                                    background: '#3B8BC6'
                                }}
                                icon="pi pi-search"
                                className=""
                            />
                        </div>
                    </div>
                </form>

                {loading ? (
                    <div className="loader-div">
                        <ProgressSpinner />
                    </div>
                ) : null}
                {!isResult ? (
                    <div className="no-search-div">
                        <div className="graphic-wrapper">
                            <div className="graphic-img">
                                <img className="" src={graphic} alt="graphic" />
                            </div>
                            {noDataFound ? (
                                <div className="text" style={{ color: 'red' }}>
                                    No Data Found
                                </div>
                            ) : (
                                <div className="text">
                                    You can search all the categories or sections using above search box.
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="search-results">
                        {foundResult?.length ? (
                            <>
                                <div>
                                    {foundResult.map((result, index) => {
                                        return (
                                            <>
                                                <div className="result-div" key={`resultDiv${index}`}>
                                                    {result?.Module?.toLowerCase() == 'standard reports' &&
                                                        result?.type?.toLowerCase() ==
                                                        'key points' ? null : result?.Module?.toLowerCase() ==
                                                            foundResult[index - 1]?.Module?.toLowerCase() &&
                                                            result?.type?.toLowerCase() ==
                                                            foundResult[index - 1]?.type?.toLowerCase() ? null : (
                                                        <div className="result-header">
                                                            {result?.Module}
                                                            {result?.type ? ' - ' + result.type : ''}
                                                        </div>
                                                    )}
                                                    {renderSearchResult(result, index)}
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                                <Paginator
                                    first={basicFirst}
                                    rows={basicRows}
                                    totalRecords={totalRecordsCount}
                                    onPageChange={pageChange}
                                ></Paginator>
                            </>
                        ) : null}
                    </div>
                )}
            </>
        );
    };
    let data = JSON.parse(sessionStorage.getItem('USER_D'));
    const cardRef = useRef();
    const handleClickOutside = (e) => {
        if (cardRef.current.contains(e.target)) {
            return; // inside click
        }
        setShowCard(false); // outside click
    };

    useEffect(() => {
        if (showCard) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showCard]);

    const getHeaderPath = (name) => {
        switch (name) {
            case 'Data Factory':
                return {
                    path: '/data-factory',
                    breadcrumbArr: ['Home', 'Data Factory']
                };
            case 'Standard Reports':
                return {
                    path: '/standard-reports',
                    breadcrumbArr: ['Home', 'Standard Reports']
                };
            case 'Insights Studio':
                return {
                    path: '/insights-studio',
                    breadcrumbArr: ['Home', 'Insights Studio']
                };
            case 'High-Cost Claimants':
                return {
                    path: '/high-cost-claimants',
                    breadcrumbArr: ['Home', 'High-Cost Claimants']
                };
            case 'Product Evaluation':
                return {
                    path: '/product-evaluation',
                    breadcrumbArr: ['Home', 'Product Evaluation']
                };
            case 'Physical Wellbeing':
                return {
                    path: '/physical-wellbeing',
                    breadcrumbArr: ['Home', 'Physical Wellbeing']
                };
            case 'Mental Wellbeing':
                return {
                    path: '/mental-wellbeing',
                    breadcrumbArr: ['Home', 'Mental Wellbeing']
                };
            case 'Emotional Wellbeing':
                return {
                    path: '/emotional-wellbeing',
                    breadcrumbArr: ['Home', 'Emotional Wellbeing']
                };
            case 'Usage Report':
                return {
                    path: '/usage-report',
                    breadcrumbArr: ['Home', 'Usage Report']
                };
            case 'My Profile':
                return {
                    path: '/my-profile',
                    breadcrumbArr: ['Home', 'My Profile']
                };
            case 'Access Management':
                return {
                    path: '/access-management',
                    breadcrumbArr: ['Home', 'Access Management']
                };
            case 'Risk Score':
                return {
                    path: '/risk-score',
                    breadcrumbArr: ['Home', 'Risk Score']
                };
            case 'Work In Process':
                return {
                    path: '/work-in-process',
                    breadcrumbArr: ['Home', 'Work In Process']
                };
            default:
                return {
                    path: '/home',
                    breadcrumbArr: ['Home']
                };
        }
    };

    const isAccessManagementAvailable = () => {
        return false;

        if (currentEnvironment !== 'DEMO' || currentEnvironment !== 'PROD') return true; //This enables access management on dev and uat which was not historically the case.

        if (sessionStorage.getItem('USER_ROLE').includes('SIEAdminProdCmcst')) return true;

        return false;
    };

    return (
        <>
            <header className="appLevelHeader">
                <img className="header-app-logo" src={appLogo} alt="Header_Logo" />

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1rem'
                    }}
                >
                    <Link
                        to={getHeaderPath(props.title).path}
                        onClick={() => getHeaderPath(props.title).breadcrumbArr}
                        style={{
                            width: '2.6rem',
                            height: '2.6rem',
                            backgroundColor: '#e9f6ff',
                            borderRadius: '50%',
                            padding: '0.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: 'none',
                            textDecoration: 'none'
                        }}
                    >
                        <img
                            style={{ height: '100%', width: '100%' }}
                            src={getpath(history?.location?.pathname)}
                            alt="icon"
                        />
                    </Link>

                    <Link
                        style={{ textDecoration: 'none' }}
                        to={getHeaderPath(APP_HEADER).path}
                        onClick={() => getHeaderPath(APP_HEADER).breadcrumbArr}
                    >
                        <h4 style={{ color: '#1D679E', fontSize: '1.4rem' }}>
                            {APP_HEADER == 'Home' ? 'SIE Healthcare Suite' : APP_HEADER}
                        </h4>
                    </Link>
                </div>
                {/* ENABLE THIS TO ENABLE THE GLOBAL SEARCH BUTTON */}
                {/* <div style={{ width: '35px' }}>
                    <Button className="search-btn" label="Search" onClick={() => setDisplaySearchPopup(true)}>
                        <img className="search-btn-icon" src={searchIcon} alt="search" />
                    </Button>
                </div> */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="" style={{ paddingRight: '17px', paddingLeft: '20px' }}>
                        <div>
                            {showCard ? (
                                <div ref={cardRef}>
                                    <Card className="profile-card">
                                        {/* ============= USER PHOTO OR INITIALS============= */}
                                        {data?.name ? (
                                            <Avatar
                                                style={{
                                                    borderRadius: '40px',
                                                    width: '65px',
                                                    height: '65px',
                                                    marginBottom: '15px'
                                                }}
                                                label={getInitials(data.name)}
                                                shape="circle"
                                            />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    borderRadius: '40px',
                                                    width: '65px',
                                                    height: '65px',
                                                    marginBottom: '15px'
                                                }}
                                                image="https://picsum.photos/200"
                                                shape="circle"
                                            />
                                        )}
                                        {/* ============= USER NAME============= */}
                                        <div className="user-name">{data?.name}</div>

                                        {/* ============= USER EMAIL============= */}
                                        <div className="user-email">{data?.userName}</div>

                                        <div className="link-container">
                                            {/* ============= USER PROFILE============= */}
                                            <div
                                                className="profile-links-parent"
                                                onClick={() => {
                                                    // if (currentEnvironment == 'PROD') {
                                                    //   alert('My Profile coming soon....');
                                                    // } else {
                                                    setShowCard(!showCard);
                                                    // }
                                                }}
                                            >
                                                <ProfileAvatar />
                                                {currentEnvironment == 'PROD' || currentEnvironment == 'PRODNEW' ? (
                                                    <Link to={`/my-profile`} className="profile-links">
                                                        My Profile
                                                    </Link>
                                                ) : (
                                                    <Link to={`/my-profile`} className="profile-links">
                                                        My Profile
                                                    </Link>
                                                )}
                                            </div>
                                            {/* ============= ACCESS MGMT============= */}
                                            {/* <div
                                                className="profile-links-parent"
                                                onClick={() => {
                                                    if (currentEnvironment == 'PROD' && currentEnvironment == 'DEMO') {
                                                        alert('Access Management coming soon....');
                                                    } else {
                                                        // if(JSON.parse(sessionStorage.getItem('USER_ROLE').includes('SIEAdminProdCmcst'))){
                                                        setShowCard(!showCard);
                                                        // }
                                                        // else{alert('Access Management is only available for admin')}
                                                    }
                                                }}
                                            >
                                                <AccessManagementIcon />
                                               
                                                {currentEnvironment == 'PROD' && currentEnvironment == 'DEMO' ? (
                                                    <Link className="profile-links">Access Management</Link>
                                                ) : JSON.parse(
                                                      sessionStorage.getItem('USER_ROLE').includes('SIEAdminProdCmcst')
                                                  ) ? (
                                                    <Link to="/access-management" className="profile-links">
                                                        Access Management
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        className="profile-links"
                                                        onClick={() =>
                                                            alert('Access Management is only available for admin.')
                                                        }
                                                    >
                                                        Access Management
                                                    </Link>
                                                )}
                                            </div> */}

                                            {isAccessManagementAvailable() && (
                                                <div
                                                    className="profile-links-parent"
                                                    onClick={() => {
                                                        setShowCard(!showCard);
                                                    }}
                                                >
                                                    <AccessManagementIcon />

                                                    <Link to="/access-management" className="profile-links">
                                                        Access Management
                                                    </Link>
                                                </div>
                                            )}

                                            {/* ============= SIGN OUT ============= */}
                                            <div className="" onClick={signOut}>
                                                <Logout />
                                                <Link className="profile-links">Sign Out</Link>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            ) : (
                                <></>
                            )}
                            {data?.name ? (
                                <Avatar
                                    style={{
                                        borderRadius: '40px',
                                        width: '36px',
                                        height: '36px',
                                        fontWeight: 500
                                    }}
                                    label={getInitials(data.name)}
                                    shape="circle"
                                    onClick={() => {
                                        setShowCard(!showCard);
                                    }}
                                />
                            ) : (
                                <Avatar
                                    style={{
                                        borderRadius: '40px',
                                        width: '36px',
                                        height: '36px',
                                        fontWeight: 500
                                    }}
                                    image="https://picsum.photos/200"
                                    shape="circle"
                                    onClick={() => {
                                        setShowCard(!showCard);
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <div
                        style={{
                            borderLeft: '1px solid #E4E4F1',
                            height: '38px',

                            position: 'relative',

                            // marginRight: '10px',
                            marginLeft: '10px'
                        }}
                    ></div>
                    <img
                        className="company-logo"
                        style={{ marginRight: '20px' }}
                        src={currentEnvironment === 'PROD' || currentEnvironment === 'PRODNEW' ? ClientLogo : DevClientLogo}
                        alt="XYZ_Company"
                    />
                </div>
            </header>

            <Dialog
                header={`Search`}
                visible={displaySearchPopup}
                draggable={false}
                onHide={() => {
                    setDisplaySearchPopup(false);
                    resetSearch();
                }}
                className="search-dialog"
            >
                {renderSearchPopupContent()}
            </Dialog>
        </>
    );
}

const mapStateToProps = (state) => ({
    userData: state.user.userData,
    sidebarData: state.datafactory.sidebarData
});

export default connect(mapStateToProps, { saveBreadCrumbData })(AppHeader);
